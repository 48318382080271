import React from 'react'
import './about.css'

import Pdcvep from "@/assets/icons/theLogo.png"
import BanqueAfricaine from "@/assets/img/banque-africaine.png"
import Minepia from "@/assets/img/minepia.png"
import Porc from "@/assets/img/porcAcc.jpg"
import Bovin from "@/assets/img/bovin.jpg"
import Pisciculture from "@/assets/img/pisciculture.jpg"

export default function About(props) {

    const logoList = [
        {
          id: 1,
          src: Pdcvep,
          alt: "Banque Mondiale",
        },
        {
          id: 2,
          src: BanqueAfricaine,
          alt: "Banque Africaine ",
        },
        {
          id: 3,
          src: Minepia,
          alt: "Minepia",
        },
      ];

    return (
        <div className='about'>
            <div className='about-image' id="a-propos">
                <div className='about-text'>
                    <h3>A propos</h3>
                    <hr />
                    <div className="about-p">
                        <p>
                            Naviguez sur la plateforme dédiée aux filières porcines, bovines et piscicoles de l’antenne régionale n°1
                            (<span className='pdcvep'>AR1</span>) du Projet de développement des chaines de valeur de l’élevage et de la pisciculture (<span className='pdcvep'>PDCVEP</span>). Explorez
                            ces trois spéculations, suivez les acteurs clés, connectez-vous pour des collaborations fructueuses.
                            Découvrez les dernières avancées du projet et relevez les défis. Professionnels, passionnés ou entrepreneurs,
                            la plateforme vous offre des opportunités uniques. Rejoignez-nous pour qu’ensemble, nous apportions notre
                            concours à l’atteinte des objectifs du projet. Connectez-vous au service de l'innovation et de la
                            collaboration.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container-card">
                <div className="card">
                    <h3>Pisciculture</h3>
                    <img src={Pisciculture} alt="Pisciculture"/>
                    <div className="card-text">
                        <p>
                            Découvrez la pisciculture sur notre plateforme où, passionnés et professionnels partagent les dernières
                            tendances, les techniques et pratiques durables. Connectez-vous avec des experts, explorez les innovations
                            de l’univers piscicole en plein essor.
                        </p>
                    </div>
                </div>

                <div className="card">
                    <h3>Elevage porcin</h3>
                    <img src={Porc} alt="Élevage porcin"/>
                    <div className="card-text">
                        <p>
                            Explorez l'élevage porcin, où l'innovation rencontre l'élevage moderne. Restez informés sur l’amélioration
                            génétique, la nutrition, la biosécurité et les méthodes d'élevage durables. Connectez-vous avec les
                            professionnels de la filière, échangez les connaissances et collaborez avec ces derniers pour relever les
                            défis de cette filière.
                        </p>
                    </div>
                </div>

                <div className="card">
                    <h3>Elevage bovin</h3>
                    <img src={Bovin} alt="Élevage bovin"/>
                    <div className="card-text">
                        <p>
                            Explorer l'élevage bovin sur notre plateforme. Restez informés sur la nutrition, la gestion des pâturages
                            et les innovations technologiques (insémination artificielles). Connectez-vous avec les éleveurs professionnels, les vétérinaires et les
                            chercheurs pour échanger les idées et partager les meilleures pratiques dans le secteur.
                        </p>
                    </div>
                </div>
            </div>

            <div className="sponsor">
                <h3>Merci à nos partenaires financiers pour leur soutien précieux dans la réalisation de ce projet.</h3>
                <div className="sponsor-logos">
                    {logoList.map((image) => (
                        <img className='sponsor-logo' key={image.id} src={image.src} alt={image.alt}/>
                    ))}
                </div>
            </div>
        </div>
    )
}
