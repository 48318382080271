import React, { useState } from 'react'
import './verify.css'
import { Link, useParams } from 'react-router-dom'
import { generateOtp, regenerateOtp, verifieAccount } from '../../../services/Crud'
import Footer from '../welcome/footer/Footer'
import LoadingButton from '@/components/LodingButton/LoadingButton'
import { useForm } from 'react-hook-form'

const Verify = () => {
    const { email } = useParams();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [err, setErr] = useState(false);
    const [regOtp, setRegOtp] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [renOtpMessage, setRenOtpMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
          setValue('otp', value.slice(0, 6));
        }
      };

    const submitForm = async (data) => {
        let otp = data.otp;
        try {
            setLoading(true);
            const response = await verifieAccount('actors', email, otp)
            setResponseMessage(response);
        } catch (error) {
            setErr(true);
            setErrorMessage(error.response.data.message);
        } finally {
            setLoading(false);
        }
    }

    const regenerateCode = async ()  => {
        try {
            setValue('otp', null);
            setLoading(true);
            setRegOtp(true);
            const response = await generateOtp('users', email)
            setRenOtpMessage(response);
        } catch (error) {
            setErr(true);
            setErrorMessage(error.response.data.message);
        } finally {
            setLoading(false);
            setRegOtp(false);
        }
    }

    return (
        <>
            {/* <NavBar/> */}
            <div className="l-body">
                {responseMessage == "1" ? 
                    <span className='success-otp'>Votre compte a été enregistré avec success.<br />
                    Vous recevrez un mail avec vos identifiants après activation de votre compte par notre équipe. <br />
                    Merci de nous faire confiance. <br />
                    <Link to="/members" className='ok-otp'>Ok</Link>
                    </span>
                    :
                    <div className="login-container">
                    <div className="l-title">
                            <h3>Verification de compte</h3>
                        <hr />
                        {!regOtp && responseMessage !== "" && responseMessage == "2" && <span>Votre code a expiré veuillez réssayer</span>}
                        {!regOtp && renOtpMessage !== "" && <span>{renOtpMessage}</span> }
                        {!regOtp && errorMessage == "" && responseMessage !== "1"  && <span>Un code vous à été envoyé par mail veuillez saisir ce code</span>}
                        {!regOtp && err && responseMessage == "" && <span className='error-message'>{errorMessage}</span>}
                    </div>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <div className="form-group">
                            <input type="text" {...register("otp", { required: true, minLength: 6, maxLength: 6 })}
                                onChange={handleInputChange}
                                id="login"/>
                                <label htmlFor="login">Saisissez le code</label>
                        </div>
                        
                        {!loading && <button type="submit" className="submit-btn">Envoyer</button>}
                        {loading && <button className="loading-btn"><LoadingButton color={"#fff"} loading={loading} /></button>}
                        {responseMessage !== "" && responseMessage == "2" &&
                        <span className='rengen-otp' onClick={regenerateCode}>Renvoyer le code ?</span>
                        }
                        </form>
                </div>
                }
                
            </div>
           
            <Footer/>
        </>
    )
}

export default Verify;
