import { useEffect, useState } from 'react'
import { getList } from '../../services/Crud';
import Message from '../message/Message'
import './rightBar.css'

export default function RightBar() {

  const [users, setUsers] = useState([]);

  useEffect(() => {
    handleGetAllUsers();
  },[])

  const handleGetAllUsers = async () => {
    try {
      const list = await getList("actors");
      setUsers(list.filter(acc => (acc.name !== "ADMINISTRATEUR" && acc.statut === true)));
    } catch (error) {
      console.error('Erreur lors du chargement des données :', error);
    }
  }

  return (
    <div className='rightBar'>
      <div className="rightBar-container">
        <Message accounts={users} />
        {/* <FriendRequest/> */}
      </div>
    </div>
  )
}
