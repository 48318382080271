import React from 'react';
import Modal from 'react-modal';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#ffffff',
      boxShadow: '0 4px 8px rgba(0.8, 0.8, 0.8, 0.9)',
      borderRadius: '8px',
      margin: '20px',
    },
    // Ajoutez des styles spécifiques aux éléments à l'intérieur de la modal
    modalHeader: {
      marginBottom: '20px',
      textAlign: 'center',
    },
    modalText: {
      marginBottom: '20px',
  },
  confirmBtn: {
    textAlign: 'center'
  },
    deleteButton: {
      backgroundColor: '#e74c3c',
      color: '#fff',
      border: 'none',
      padding: '10px 15px',
      marginRight: '10px',
      cursor: 'pointer',
    },
    cancelButton: {
      backgroundColor: '#3498db',
      color: '#fff',
      border: 'none',
      padding: '10px 15px',
      cursor: 'pointer',
    },
  };

const DeleteConfirmationModal = ({ isOpen, onClose, onDelete, title, message }) => {
  return (
    <div style={{zIndex:1000}}>

    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Delete Confirmation Modal"
      // className='passModal'
    >
      <div style={customStyles.modalHeader}>
        <h2>{title}</h2>
      </div>
      <div style={customStyles.modalText}>
        <p>{message}</p>
      </div>
      <div style={customStyles.confirmBtn}>
        <button style={customStyles.deleteButton} onClick={onDelete}>
          Oui Supprimer
        </button>
        <button style={customStyles.cancelButton} onClick={onClose}>
          Annuler
        </button>
      </div>
    </Modal>
      </div>
  );
};

export default DeleteConfirmationModal;
