import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

const LoadingButton = ({ loading, color }) => {
    return (
        <div>
            <BeatLoader color={color} loading={loading} />
        </div>
    );
};

export default LoadingButton;