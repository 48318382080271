import React from 'react';
import { Outlet } from 'react-router-dom';

import Nav from '@/components/nav/Nav'
import LeftBar from '@/components/leftbar/LeftBar'
import RightBar from '@/components/rightbar/RightBar'

const MLayourt = () => {
    return (
        <>
            <Nav/>
            <main>
                <LeftBar/>
                <div className="container">
                    <Outlet/>
                </div>
                <RightBar/>
            </main>
        </>
    );
};

export default MLayourt;