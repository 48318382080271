import React, { useEffect, useState } from 'react';
import { createItem, getItemById, getList, updateItem } from '../../services/Crud';
import './accountForm.css';
import LoadingButton from '@/components/LodingButton/LoadingButton'
import { batimentgOptions, bovinOption, infrastructureBovinOptions, infrastructureOptions, piscicultureOption, porcOption, productionOptions, raceBovinOptions, raceOptions, speciesOptions } from '../enums/CategorieOption';
import { useForm } from 'react-hook-form';
import { arrondissement, departement, region } from '../enums/RegionOption';
import { useNavigate } from 'react-router';

const AccountForm = ({ mode, userId, onSubmit }) => {

  const navigate = useNavigate();

  const { register, handleSubmit, setValue, watch, formState: { errors }, reset } = useForm();
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedCategoryObject, setSelectedCategoryObject] = useState(null);

  const selectedCategory = watch('categorie');
  const selectedRegion = watch('region');
  const selectedDepartement = watch('deptement');

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    handleGetAllCategories();
    if (mode === 'update') {
      const getUser = async () => {
        try {
          const response = await getItemById('actors', parseInt(userId));
          setPreviewImage(response.imgProfile);
          reset(response);
          setValue('categorie', response.categorie.catName);
        } catch (error) {
          console.error('Erreur lors de la récupération des données de l\'utilisateur :', error);
        }
      };
      getUser();
    }
    switch (selectedCategory) {
        case 'Pisciculture':
          setValue('typeInfra', '');
          setValue('typeEspece', '');
          break;
        case 'Porc':
          setValue('typeProduction', '');
          setValue('typeRace', '');
          setValue('typeBatiment', '');
          break;
        default:
          break;
      }
  }, [mode, userId, (selectedRegion, selectedDepartement, selectedCategory, setValue)]);

const handleGetAllCategories = async () => {
    try {
        const data = await getList('categories');
        setCategories(data);

        if (selectedCategory) {
            const selectedCategoryObject = data.find(cat => cat.catName === selectedCategory);
            setSelectedCategoryObject(selectedCategoryObject);
        }
    } catch (error) {
        console.error('Erreur lors de la récupération des catégories :', error);
    }
};

const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
    reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };

  const convertImageToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const submitForm = async (data) => {
    try {
      setLoading(true);
  
      let formDataWithImage = { ...data };
      const selectedCategoryObject = categories.find(cat => cat.catName === data.categorie);
      formDataWithImage = { ...formDataWithImage, categorie: selectedCategoryObject };

      if (mode === 'create') {

        if (data.imgProfile && data.imgProfile.length > 0) {
          const imageFile = data.imgProfile[0];
          const imageBase64 = await convertImageToBase64(imageFile);
          formDataWithImage = { ...formDataWithImage, imgProfile: imageBase64 };
        } else {
          formDataWithImage = { ...formDataWithImage, imgProfile: "" };
        }

          const response = await createItem('actors', formDataWithImage);
          navigate(`/verify/${response.email}`)
        
      } else if (mode === 'update') {
          formDataWithImage = { ...formDataWithImage, imgProfile: previewImage };
          console.log(formDataWithImage);
          const resp = await updateItem('actors', userId, formDataWithImage);
          onSubmit(resp.data);
        }
    } catch (error) {
      setErrorMessage(error.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  
  
  return (
    <div className="body">
    <section className='s-container'>
        {mode === 'create' && <header>Créer votre compte</header>}
        {mode === 'update' && <header>Modifier vos informations</header>}
        {errorMessage !== "" && <span className='err-msg1'>{errorMessage}</span>}
        <form onSubmit={handleSubmit(submitForm)} className="form">
          <div className="column">
            <div className="input-box">
              <label htmlFor="name">
                <input
                  type="text"
                  {...register("name", { required: 'Renseignez un nom' })}
                  id="name"
                />
                <span className='span'>Nom</span>
              </label>
              {errors.name && <em className='err-msg'>{errors.name.message}</em>}
            </div>
            <div className="input-box">
              <label htmlFor="email">
                <input
                  type="email"
                  {...register("email", {
                    required: 'Renseignez une adresse email',
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: 'Adresse email invalide'
                    }
                  })}
                  id="email"
                />
                <span className='span'>Email</span>
              </label>
              {errors.email && <em className='err-msg'>{errors.email.message}</em>}
            </div>
          </div>

          <div className="column">
            <div className="input-box">
              <label htmlFor="phoneOne">
                <input
                  type="tel"
                  {...register("phoneOne", {
                    required: 'Renseignez le numéro de téléphone 1',
                    pattern: {
                      value: /^\d{9,9}$/,
                      message: 'Numéro de téléphone invalide'
                    }
                  })}
                  id="phoneOne" name="phoneOne"
                />
                <span className='span'>Téléphone 1 <em>(Sans indicatif)</em> </span>
              </label>
              {errors.phoneOne && <em className='err-msg'>{errors.phoneOne.message}</em>}
            </div>
            <div className="input-box">
              <label htmlFor="phoneTwo">
                <input
                  type="tel"
                  {...register("phoneTwo", {
                    pattern: {
                      value: /^\d{9,9}$/,
                      message: 'Numéro de téléphone invalide'
                    }
                  })}
                  id="phoneTwo"
                />
                <span className='span'>Téléphone 2</span>
              </label>
              {errors.phoneTwo && <em className='err-msg'>{errors.phoneTwo.message}</em>}
            </div>
          </div>

          <div className="localisation">
            <div className="column">
              <div className="select-box">
                <select {...register("region", { required: 'Sélectionnez une région' })}>
                  <option value="" hidden>Région</option>
                  {region.map((region, index) => (
                    <option key={index} value={region}>
                      {region}
                    </option>
                  ))}
                </select>
                {errors.region && <em className='err-msg'>{errors.region.message}</em>}
              </div>

              {departement[selectedRegion] && (
                <div className="select-box">
                  <select {...register("deptement", { required: 'Sélectionnez un département' })}>
                    <option value="" hidden>Departement</option>
                    {departement[selectedRegion]?.map(dep => (
                      <option key={dep} value={dep}>
                        {dep}
                      </option>
                    ))}
                  </select>
                  {errors.deptement && <em className='err-msg'>{errors.deptement.message}</em>}
                </div>
              )}

              {arrondissement[selectedDepartement] && (
                <div className="select-box">
                  <select {...register("arrdissement", { required: 'Sélectionnez un arrondissement' })}>
                    <option value="" hidden>Arrondissement</option>
                    {arrondissement[selectedDepartement]?.map(arr => (
                      <option key={arr} value={arr}>
                        {arr}
                      </option>
                    ))}
                  </select>
                  {errors.arrdissement && <em className='err-msg'>{errors.arrdissement.message}</em>}
                </div>
              )}
            </div>
          </div>

          <div className="categorie">
            <div className="column">
              <div className="select-box">
                <select {...register("categorie", { required: 'Sélectionnez une catégorie' })}>
                  <option value="" hidden >Sélectionnez une catégorie</option>
                  {categories.map((cat) => (
                    <option key={cat.id} value={cat.catName}>
                      {cat?.catName}
                    </option>
                  ))}
                </select>
                {errors.categorie && <em className='err-msg'>{errors.categorie.message}</em>}
              </div>

              {selectedCategory === 'Pisciculture' && (
                <div className="select-box">
                  <select {...register("profession", { required: 'Sélectionnez une spéculation' })}>
                    <option value="" hidden>Spéculation</option>
                    {piscicultureOption.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {errors.profession && <em className='err-msg'>{errors.profession.message}</em>}
                </div>
              )}

              {selectedCategory === 'Bovin' && (
                <div className="select-box">
                  <select {...register("profession", { required: 'Sélectionnez une spéculation' })}>
                    <option value="" hidden>Spéculation</option>
                    {bovinOption.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {errors.profession && <em className='err-msg'>{errors.profession.message}</em>}
                </div>
              )}

              {selectedCategory === "Porc" && (
                <div className="select-box">
                  <select {...register("profession", { required: 'Sélectionnez une spéculation' })}>
                    <option value="" hidden>Spéculation</option>
                    {porcOption.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {errors.profession && <em className='err-msg'>{errors.profession.message}</em>}
                </div>
              )}
            </div>

            <div className="column">
              {selectedCategory === "Pisciculture" && (
                <>
                  <div className="select-box">
                    <select {...register("typeEspece", { required: 'Sélectionnez un type d\'espèce' })}>
                      <option value="" hidden>Type d'espèce</option>
                      {speciesOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {errors.typeEspece && <em className='err-msg'>{errors.typeEspece.message}</em>}
                  </div>
                  <div className="select-box">
                    <select {...register("typeInfra", { required: 'Sélectionnez un type d\'infrastiture' })}>
                      <option value="" hidden>Type d'infrastiture</option>
                      {infrastructureOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {errors.typeInfra && <em className='err-msg'>{errors.typeInfra.message}</em>}
                  </div>
                </>
              )}
            </div>

            {/* bovin option */}
            <div className="column">
              {selectedCategory === "Bovin" && (
                <>
                  <div className="select-box">
                    <select {...register("typeEspece", { required: 'Sélectionnez un type d\'espèce' })}>
                      <option value="" hidden>Type de race</option>
                      {raceBovinOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {errors.typeEspece && <em className='err-msg'>{errors.typeEspece.message}</em>}
                  </div>
                  <div className="select-box">
                    <select {...register("typeInfra", { required: 'Sélectionnez un type d\'infrastiture' })}>
                      <option value="" hidden>Type d'infrastiture</option>
                      {infrastructureBovinOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {errors.typeInfra && <em className='err-msg'>{errors.typeInfra.message}</em>}
                  </div>
                </>
              )}
            </div>

            <div className="column">
              {selectedCategory === "Porc" && (
                <>
                  <div className="select-box">
                    <select {...register("typeBatiment", { required: 'Sélectionnez un type de bâtiment' })} id="batimentType">
                      <option value="" hidden>Type de batiment</option>
                      {batimentgOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {errors.typeBatiment && <em className='err-msg'>{errors.typeBatiment.message}</em>}
                  </div>

                  <div className="select-box">
                    <select {...register("typeRace", { required: 'Sélectionnez un type de race' })} id="raceType">
                      <option value="" hidden>Type de race</option>
                      {raceOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {errors.typeRace && <em className='err-msg'>{errors.typeRace.message}</em>}
                  </div>
                  <div className="select-box">
                    <select {...register("typeProduction", { required: 'Sélectionnez un type de production' })} id="productionType">
                      <option value="" hidden>Type de production</option>
                      {productionOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {errors.typeProduction && <em className='err-msg'>{errors.typeProduction.message}</em>}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="column">
            <div className="input-box">
              <label htmlFor="description">
                <textarea {...register("description", { required: 'Renseignez une description', minLength: 15 })} id="descrition" style={{ width: '100%', resize: 'vertical' }}
                  placeholder='Decrivez votre structure en quelques mots'
                ></textarea>
              </label>
              <em className='alt-desc'>(Minimum 25 charactères pour la description )</em> <br/>
              {errors.description && <em className='err-msg'>{errors.description.message}</em>}
            </div>
          </div>

          <div className="column">
            <div className="input-box">
              <label htmlFor="siteWeb">
                <input type="text" {...register("siteWeb", {})} placeholder='monSite.com' />
                <span className='span'>Site web <em>(monSite.com)</em> </span>
              </label>
            </div>
            <div className="input-box">
              <label htmlFor="imgProfile">
                <input type="file" accept="image/*" {...register("imgProfile")} onChange={handleImageChange} />
                <span className="span">Logo</span>
              </label>
            </div>
            {previewImage && (
              <div className="image-preview">
                <img src={previewImage} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
              </div>
            )}
          </div>

          {!loading && <button type="submit" className="submit-btn">Enregistrer</button>}
          {loading && <button className="loading-btn"><LoadingButton color={"#fff"} loading={loading} /></button>}
        </form>
    </section>
    </div>
  );
};

export default AccountForm;
