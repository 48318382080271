import React from 'react';
import { Navigate } from 'react-router-dom';
import { authService } from './auth.service';

const AuthGuard = ({ children }) => {

    if (!authService.isLogged()) {
        return <Navigate to="/login"/>;
    }
    return children;
};

export default AuthGuard;