import React from 'react'
import { Link } from 'react-router-dom'
import './header.css'
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import poisson from '@/assets/img/bannerPoisson.jpg'
import porc from '@/assets/img/porc1.jpg'
import bovin from '@/assets/img/bovin1.jpg'


export default function Header() {
    const AutoplaySlider = withAutoplay(AwesomeSlider);

    const sliderConfig={
        play: true,
        interval: 2500,
        bullets: false, // Désactiver les points indicateurs
        organicArrows: false,
    }
    return (
        <div className='main'>
            <div className='slider-overlay'></div>
            <AutoplaySlider {...sliderConfig} >
                <div data-src={poisson} />
                <div data-src={bovin} />
                <div data-src={porc} />
            </AutoplaySlider>
            <div className='name'>
                <h1> <span className='sh1'>Bienvenue</span><span className='sh2'>sur la plateforme régionale de l'antenne n° 1 du</span><span className='sh3'>PDCVEP</span>   </h1>
                <p>Découvrez et entrez en contact avec les acteurs des differentes chaînes de valeur de l'élévage</p>
                <Link to="/members/home" className='btn btn-primary'>Voir les articles</Link>
            </div>
        </div>

    )
}
