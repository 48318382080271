import React from 'react';
import './editProfil.css'
import { useParams } from 'react-router-dom';
import AccountForm from '../../../components/forms/AccountForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Error from '../../../_utils/Error';

import { jwtDecode } from 'jwt-decode';

const EditProfil = () => {

    const { id } = useParams();

    const userId = jwtDecode(localStorage.getItem("token")).id;

    const handleSubmit = async (e) => {window.history.back();
        toast.success("Votre profil à été mise à jour!");
    }

    return (
        <div className="body">
            {userId === parseInt(id) ?
                <section className="">
                    <AccountForm mode="update" userId={id} onSubmit={handleSubmit} />
                </section> :
                <Error/>
            }
            <ToastContainer />
        </div>
   )
};

export default EditProfil;