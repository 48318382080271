import React from 'react'
import './faq.css'

export default function Footer() {
  return (
    <div className='faq-content'>

        <h1>Politique de Confidentialité</h1>

        <p>
            Cette Politique de Confidentialité décrit comment AR1/PDCVEP collecte, utilise et protège les
            informations personnelles des utilisateurs de notre application.
        </p>

        <h2>Collecte et Utilisation des Informations</h2>

        <p>
            Nous collectons certaines informations personnelles lorsque les utilisateurs interagissent avec notre
            application, telles que les données d'inscription (nom, adresse e-mail, etc.) fournies lors de la 
            création d'un compte, les données de profil (photo de profil, biographie, etc.) fournies volontairement
            par les utilisateurs, et les données d'interaction (articles publiés, commentaires, likes, etc.) collectées
            lors de l'utilisation de notre application.
        </p>

        <p>
            Nous utilisons ces informations pour créer et gérer les comptes des utilisateurs, personnaliser leur
            expérience sur notre application, faciliter les interactions entre utilisateurs, fournir des fonctionnalités
            et des services de notre application, analyser et améliorer nos services, répondre aux demandes des utilisateurs,
            communiquer avec les utilisateurs, et assurer la sécurité et l'intégrité de notre application.
        </p>

        <h2>Partage d'Informations</h2>

        <p>
            Nous ne partageons pas les informations personnelles des utilisateurs avec des tiers, sauf dans les cas suivants :
            avec le consentement des utilisateurs, pour répondre à des obligations légales, pour protéger nos droits et
            intérêts légitimes, ou en cas de fusion, acquisition ou vente de tout ou partie de notre entreprise.
        </p>

        <h2>Protection des Informations</h2>

        <p>
            Nous prenons des mesures raisonnables pour protéger les informations personnelles des utilisateurs contre
            toute perte, utilisation abusive, accès non autorisé, divulgation, altération ou destruction. Cependant,
            aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée, et nous
            ne pouvons garantir la sécurité absolue des informations des utilisateurs.
        </p>

        <h2>Modifications de la Politique de Confidentialité</h2>

        <p>
            Nous nous réservons le droit de modifier cette Politique de Confidentialité à tout moment. Les utilisateurs
            seront informés des modifications apportées à cette Politique de Confidentialité par le biais d'une notification
            dans notre application. L'utilisation continue de notre application après la publication de modifications
            de cette Politique de Confidentialité constitue l'acceptation de ces modifications par les utilisateurs.
        </p>

        <h2>Contactez-nous</h2>

        <p>
            Si vous avez des questions ou des préoccupations concernant notre Politique de Confidentialité, veuillez nous
            contacter à <a href="mailto:etiennewedjou@yahoo.com">etiennewedjou@yahoo.com</a>.
        </p>

    </div>
  )
}
