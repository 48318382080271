import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal'; // Ajout de cette ligne
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root')
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
