import './comments.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faTrash} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { createItem, deleteItem, getComentByArticle, getItemById } from '../../services/Crud'
import { useEffect } from 'react'
import { jwtDecode } from 'jwt-decode'
import { authService } from '../../services/auth/auth.service';


const Comments = ({ post }) => {

    const token = localStorage.getItem('token');
    const actorId = token ? jwtDecode(token).id : "";
    const [coments, setComents] = useState([]);
    const [userr, setUserr] = useState({});
    const [isButtonVisible, setIsButtonVisible] = useState(false);

    const article = {
        id: post.id,
        title: post.title,
        description: post.description,
        date: post.date,
        updateAt: post.updateAt
    }
    
    const [comentData, setComentData] = useState({
        content: '',
        acteur: {},
        article: article
    })

    useEffect(() => {
        if (actorId) {
            handleGetActorById(actorId);
        }
        handleGeComentsByPost();
    }, []);

    const handleGeComentsByPost = async () => {
        try {
          const data = await getComentByArticle('coments/article', post.id);
            setComents(data);
        } catch (error) {
          console.error(error.response.data.message);
        }
    }

    const handleGetActorById = async (id) =>{
        try {
            const data = await getItemById('actors/actor', parseInt(id));
            setUserr(data);
            setComentData((prevData) => ({...prevData, acteur: data }))
        } catch (error) {
          console.error(error.response.data.message);
        }
      }

    const handleInputChange = (e) => {
        const trimmedValue = e.target.value.trim();
        setComentData((prevData) => ({
            ...prevData, content: trimmedValue
        }))
        setIsButtonVisible(trimmedValue !== '');
    }

    const handleSubmit = async () => {
        try {
            await createItem("coments", comentData);
        } catch (error) {
            console.log(error.response.data.message);
        }
    }

    const handleDelete = async (id) => {
        try {
            await deleteItem("coments", id);
        } catch (error) {
            console.error(error.response.data.message);
        } finally {
            window.location.href = window.location.href;
        }
    }

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric'};
        return new Date(date).toLocaleDateString();
      };

    return (
        <div className='comments'>
            <div className="writebox">
                <form onSubmit={handleSubmit}>
                    { authService.isLogged() && !authService.isAdmin() &&
                        <div className="user">
                            <img src={userr.imgProfile} alt="" />
                            <textarea name="content" onChange={handleInputChange} placeholder='Laisser un commentaire' />
                            {isButtonVisible && <button type="submit" className='btn-cmt' title='Publier'><FontAwesomeIcon icon={faPaperPlane} /></button>
                            }
                        </div>
                    }
                </form>
            </div>
            {
                coments.map(coment=>(
                    <div  key={coment.id}>
                        <div className="user">
                            <span><Link to={`/members/profile/${coment.acteur?.id}`}><img src={coment.acteur?.imgProfile} alt="" /></Link></span>
                            <div>
                                <h5>{coment.acteur?.name}</h5>
                                <p>{coment.content}</p>
                            </div>
                            {/* <small>{formatDate(coment.date)}</small> */}
                            { actorId === coment.acteur?.id && <span className='delete-icon' onClick={(id) => handleDelete(coment.id)}><FontAwesomeIcon icon={faTrash} title="Supprimer"/></span>}
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
export default Comments;
