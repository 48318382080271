import React from 'react';
import { Navigate } from 'react-router-dom';
import { authService } from './auth.service';

const NoAdminGuard = ({ children }) => {
    
    if (authService.isLogged() && authService.isAdmin()) {
        return <Navigate to="/admin"/>;
    }
    return children;
};

export default NoAdminGuard;