import './userProfile.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity, faCodeBranch, faCog, faCow, faEdit, faEnvelope, faFish, faGlobe, faKey, faLink, faLocation, faPaw, faShapes, faShieldCat, faSquarePhone,faUniversity,faUserTie, faWarehouse} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import Profil from '../../assets/img/profil.png'
import { useEffect, useRef, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import { ToastContainer } from 'react-toastify';
import LimitText from '../feed/LimitText'
import { ClipLoader } from 'react-spinners'

const UserProfile = ({ profil, loading }) => {
    const [userId, setUserId] = useState();
    // const [isModalOpen, setIsModalOpen] = useState(false); 
 
    const [settingsOpen, setSettingsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const btnRef = useRef(null);
    const [isDropdownVisible,setIsDropdownVisible]=useState();
    const toggleSettings=()=>{
        setSettingsOpen(!settingsOpen);
    }
    // const closeModal = () => {
    //     setIsModalOpen(false);
        
    //   };
    useEffect(() => {

        let token = localStorage.getItem("token");
        if (token !== null) {
            setUserId(jwtDecode(token).id);
        }
        
        const handleClickOutside = (event) => {
          if (
            settingsOpen &&
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            btnRef.current &&
            !btnRef.current.contains(event.target)
          ) {
            // Clic en dehors du bouton et du dropdown, fermer le menu
            setIsDropdownVisible(false);
          }
        };
    
        // Ajouter le gestionnaire de clic au montage du composant
        document.addEventListener('click', handleClickOutside);
    
        // Retirer le gestionnaire de clic lors du démontage du composant
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, [isDropdownVisible]); // La dépendance isDropdownOpen pour mettre à jour le gestionnaire de clic

    
    const phoneNumber = `237${profil.phoneOne}`;
    const message="Salut !! J'ai trouvé votre profil sur la plate forme PDCVEP. J'aimerai qu'on echange"
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
  
    return (
        <>
            {loading ? 
                <div className='loading'>
                    <ClipLoader color="#36d7b7" />
                </div>
                :
                <>
                <div className='userProfile'>
                        <div className="cover-photo">
                            <img src={Profil} alt="" />
                        </div>
                        <div className="profile-info">
                            {profil.imgProfile ? <img src={profil.imgProfile} alt="" /> : <img src={Profil} alt="" />}
                            <div className="user-name">
                                <h3>{profil.name}</h3>
                                {/* <span><em>{profil.articles?.length} Posts</em> </span><br /> */}
                                <span>{profil.description}</span>
                                {/* <LimitText limit={50} text={profil.descrition} /> */}

                            </div>
                            {/* <hr /> */}
                            <div className="infos_box">
                                <div className='intro'>Informations</div>
                                <div className="infos_container">
                                    <div className='info_item'>
                                        <div className="item_left"><FontAwesomeIcon icon={faEnvelope}/></div>
                                        <div className="item_right">
                                            <span className="label">
                                                Email
                                            </span>
                                            <span>{profil.email}</span></div>
                                    </div>
                                    <div className='info_item'>
                                        <div className="item_left"><FontAwesomeIcon icon={faSquarePhone}/></div>
                                        <div className="item_right">
                                            <span className="label">
                                                Téléphone
                                            </span>
                                            <span>{profil.phoneOne}</span></div>
                                    </div>
                                    <div className="info_item">
                                        <div className="item_left"><FontAwesomeIcon icon={faCity} /></div>
                                        <div className="item_right">
                                            <span className="label">
                                                Département
                                            </span>
                                            <span>{profil.deptement}</span></div>
                                    </div>
                                    <div className="info_item">
                                        <div className="item_left"><FontAwesomeIcon icon={faUniversity} /></div>
                                        <div className="item_right">
                                            <span className="label">
                                                Arrondissement
                                            </span>
                                            <span>{profil.arrdissement}</span></div>
                                    </div>
                                    <div className="info_item">
                                        <div className="item_left"><FontAwesomeIcon icon={faLocation} /></div>
                                        <div className="item_right">
                                            <span className="label">
                                                Region
                                            </span>
                                            <span>{profil.region}</span></div>
                                    </div>
                                    <div className="info_item">
                                        <div className="item_left"><FontAwesomeIcon icon={faCodeBranch} /></div>
                                        <div className="item_right">
                                            <span className="label">
                                                Filière
                                            </span>
                                            <span>{profil.categorie?.catName}</span></div>
                                    </div>
                                    <div className="info_item">
                                        <div className="item_left"><FontAwesomeIcon icon={faUserTie} /></div>
                                        <div className="item_right">
                                            <span className="label">
                                                Maillon
                                            </span>
                                            <span>{profil.profession}</span></div>
                                    </div>
                                    {profil.categorie?.catName === "Pisciculture" && (
                                        <>
                                            <div className="info_item">
                                                <div className="item_left"><FontAwesomeIcon icon={faShieldCat} /></div>
                                                <div className="item_right">
                                                    <span className="label">
                                                        Espèce
                                                    </span>
                                                    <span>{profil.typeEspece}</span></div>
                                            </div>
                                            <div className="info_item">
                                                <div className="item_left"><FontAwesomeIcon icon={faWarehouse} /></div>
                                                <div className="item_right">
                                                    <span className="label">
                                                        Type d'infrastructure
                                                    </span>
                                                    <span>{profil.typeInfra}</span></div>
                                            </div>
                                            <div className="info_item">
                                                <div className="item_left"><FontAwesomeIcon icon={faGlobe} /></div>
                                                <div className="item_right">
                                                    <span className="label">Site Web</span>
                                                {profil.siteWeb !== "" ? (<span><Link to={`https://${profil.siteWeb}`} target="_blank">{profil.siteWeb}</Link></span>) :
                                                        <span>Aucun</span>} 
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {profil.categorie?.catName === "Porc" && (
                                        <>
                                            <div className="info_item">
                                                <div className="item_left"><FontAwesomeIcon icon={faPaw} /></div>
                                                <div className="item_right">
                                                    <span className="label">
                                                        Type de production
                                                    </span>
                                                    <span>{profil.typeProduction}</span></div>
                                                
                                            </div>
                                            <div className="info_item">
                                                <div className="item_left"><FontAwesomeIcon icon={faShapes} /></div>
                                                <div className="item_right">
                                                    <span className="label">
                                                        Race
                                                    </span>
                                                    <span>{profil.typeRace}</span></div>
                                                
                                            </div>
                                            <div className="info_item">
                                                <div className="item_left"><FontAwesomeIcon icon={faWarehouse} /></div>
                                                <div className="item_right">
                                                    <span className="label">
                                                        Batiment
                                                    </span>
                                                    <span>{profil.typeBatiment}</span></div>
                                            </div>
                                            <div className="info_item">
                                                <div className="item_left"><FontAwesomeIcon icon={faLink} /></div>
                                                <div className="item_right">
                                                    <span className="label">Site Web</span>{profil.siteWeb !== "" ? (<span><a href={`https://${profil.siteWeb}`}>{profil.siteWeb}</a></span>) :
                                                <span>Aucun</span>
                                                }</div>
                                                
                                                
                                            </div>
                                        </>
                                    )}

                                </div>
                            </div>
                            <div className="profile-button">
                                {userId === profil.id ? 
                                    <>
                                    <FontAwesomeIcon icon={faCog} title="Paramètres" style={{cursor:'pointer'}} onClick={toggleSettings}/>
                                    {
                                        settingsOpen && 
                                        <div className='settings'>
                                            <Link to={`/editpwd/${profil.id}`} title="Modifier mot de passe">
                                                <FontAwesomeIcon style={{cursor:'pointer'}} icon={faKey}/>
                                            </Link>
                                            <Link to={`/members/profile/edit/${profil.id}`} title="Modifier profil">
                                                {/* <button className="btn btn-primary"> */}
                                                    <FontAwesomeIcon icon={faEdit}/>
                                                {/* </button> */}
                                            </Link>
                                        </div>
                                    }
                                    
                                    </>
                                    : 
                                    <Link to={whatsappUrl} target="_blank" rel="noopener noreferrer" title="Discutons sur Whatsapp">
                                        <button className="btn btn-primary">
                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: 'white' }} height="16" width="14" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg>
                                            <span>(+237) {profil.phoneOne}</span>
                                        </button>
                                    </Link>
                                }
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </>
            }
        </>
    )
}

export default UserProfile;
