// regions.js
export const region = ['CENTRE', 'SUD', 'EST'];

// departments.js
export const departement = {
    CENTRE: [
        'Lekié',
        'Mbam et Inoubou',
        'Méfou et Afamba',
        'Méfou et Akono',
        'Mbam et Kim',
        'Nyong et So o',
        'Nyong et Kéllé',
        'Nyong et Mfoumou',
        'Haute Sanaga',
        'Mfoundi'
    ],
    SUD: [
        'Dja et Lobo',
        'Mvila',
        'Océan',
        'Vallée du Ntem',
    ],
    EST: [
        'Boumba et Ngoko',
        'Haut Nyong',
        'Kadey',
        'Lom et Djérem',
    ],
};

// arrondissements.js
export const arrondissement = {
    // centre
    'Lekié': [
      'Batchenga',
      'Ebebda',
      'Elig-Mfomo',
      'Evodoula',
      'Lobo',
      'Monatélé',
      'Obala',
      'Okola',
      'Sa a'
  ],
    'Mbam et Inoubou': [
        'Bafia',
        'Bokito',
        'Ndikiniméki',
        'Ombessa',
        'Deuk',
        'Makenene',
        'Kiiki',
        'Kon-Yambetta',
        'Nitoukou',
  ],
    'Méfou et Afamba': [
        'Awaé',
        'Olanguina',
        'Afanloum',
        'Edzendouan',
        'Esse',
        'Mfou',
        'Nkolafamba',
        'Soa',
  ],
    'Méfou et Akono': [
        'Akono',
        'Ngoumou',
        'Mbankomo',
        'Bikok',
  ],
    'Mbam et Kim': [
      'Mbangassina',
      'Ngambè-Tikar',
      'Ngoro',
      'Ntui',
      'Yoko'
  ],
    'Nyong et So o': [
      'Akoeman',
      'Dzeng',
      'Mbalmayo',
      'Mengueme',
      'Ngomedzap',
      'Nkolmetet'
  ],
  'Nyong et Kéllé': [
    'Nguibassal',
    'Bondjock',
    'Biyouha',
    'Dibang',
    'Matomb',
    'Ngog-Mapubi	',
    'Messondo',
    'Bot-Makak',
    'Makak',
    'Éséka'
  ],
  'Nyong et Mfoumou': [
    'Akonolinga',
    'Ayos',
    'Endom',
    'Mengang', 
    'Nyakokombo'
  ],
  'Haute Sanaga': [
    'Nanga-Eboko',
    'Minta',
    'Mbandjock',
    'Nkoteng', 
    'Bibey',
    'Lembe-Yezoum',
    'Nsem', 
  ],
  'Mfoundi': [
    'Yaoundé I (Nlongkak)',
    'Yaoundé II (Tsinga)', 
    'Yaoundé III (Efoulan)',
    'Yaoundé IV (Kondengui)',
    'Yaoundé V (Essos)',
    'Yaoundé VI (Biyem-Assi)',
    'Yaoundé VII (Nkolbisson)'
  ],
//   sud
 'Dja et Lobo': [
    'Djoum',
    'Bengbis',
    'Zoétélé',
    'Meyomessala',
    'Oveng',
    'Mintom',
    'Oveng',
    'Meyomessi',
    ],
'Mvila': [
  'Biwong-Bane',
  'Biwong-Bulu',
  'Ebolowa Ier',
  'Ebolowa IIe',
  'Efoulan',
  'Mengong',
  'Mvangan',
  'Ngoulemakong',
],
'Océan': [
    'Akom II',
    'Bibondi',
    'Campo',
    'Kribi I',
    'Kribi II',
    'Lokundje',
    'Lolodorf',
    'Mvengue',
    'Niété',
],
'Vallée du Ntem': [
    'Ambam',
    'Ma an',
    'Olamze',
    'Kyé-Ossi',
],
// est
 'Boumba et Ngoko': [
    'Gari-Gombo',
    'Moloundou',
    'Salapoumbé',
    'Yokadouma',
],
 'Haut Nyong': [
  'Abong-Mbang',
  'Bebend (Atok)',
  'Dimako',
  'Doumaintang',
  'Doumé',
  'Lomié',
  'Mboanz (Angossas)',
  'Mboma',
  'Messamena',
  'Messok',
  'Mindourou',
  'Ngoyla',
  'Nguelemendouka',
  'Somalomo',
],
'Kadey': [
    'Batouri',
    'Kentzou',
    'Kette',
    'Mbang',
    'Ndelele',
    'Nguelebok',
    'Ouli',
],
'Lom et Djérem': [
  'Bélabo',
  'Bertoua Ier',
  'Bertoua IIe',
  'Bétaré-Oya',
  'Diang',
  'Garoua-Boulaï',
  'Mandjou',
  'Ngoura',
]
};
