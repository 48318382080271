import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faPenToSquare, faTimes, faUsd } from '@fortawesome/free-solid-svg-icons'
import './float.css'
import Post from '../modals/newPost/Post';
import ReactModal from 'react-modal';
import { useState } from 'react';

const Float = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [type, setType] = useState("")
    const [showAdditionalButtons, setShowAdditionalButtons] = useState(false);
    const handleOpenModal_V = () => {
        setIsOpen(true)
        setType('vente')
    }
    const handleOpenModal_S = () => {
        setIsOpen(true)
        setType('annonce')
    }
    const toggleAdditionalButtons = () => {
        setShowAdditionalButtons(!showAdditionalButtons);
    };
    const handleCloseModal = () => {
        setIsOpen(false)
    }

    return (
        <>
            {/* <MainModal>
            <Post/>
        </MainModal> */}
            <ReactModal
                isOpen={isOpen}
                contentLabel="Minimal Modal Example"
                onRequestClose={handleCloseModal}
                className={`custom-modal ${isOpen ? 'open' : ''}`}
                overlayClassName={`custom-overlay ${isOpen ? 'open' : ''}`}
                closeTimeoutMS={300}
            >
                <Post handleCloseModal={handleCloseModal} type={type}/>
            </ReactModal>
            <div className='float'>

                <div className='float_btn' onClick={toggleAdditionalButtons}>
                    <FontAwesomeIcon icon={faAdd} />
                </div>
                {
                    showAdditionalButtons &&
                    <div className="complement">
                        <div className='float_btn' onClick={handleOpenModal_S}>
                            <FontAwesomeIcon icon={faEdit} />
                        </div>
                        <div className='float_btn' onClick={handleOpenModal_V}>
                            <FontAwesomeIcon icon={faUsd} />
                        </div>

                    </div>
                }
            </div>
        </>

    )
}
export default Float;
