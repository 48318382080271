import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from './navBar/NavBar';
import './alayourt.css'

const ALayourt = () => {
    return (
        <>
            <NavBar/>
            <div className="a-content">
                <Outlet/>
            </div>
        </>
    );
};

export default ALayourt;