import React from 'react'
import './navBar.css'
import '../welcome.css'
import TheLogo from '@/assets/icons/theLogo.png'
import { Link } from 'react-router-dom'

export default function NavBar() {
    return (
        <nav className="navbar">
            <div className="logo">
                <Link><img src={TheLogo} className="image" alt=""/></Link>
            </div>
            <ul>
                <li><Link to="/members/home">Articles</Link></li>
                <li><a href="#a-propos">A propos</a></li>
                <li><a href='#a-contact'>Contact</a></li>

            </ul>
        </nav>
    )
}
