import React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import Separate from '../../../components/separate/Separate';
import UserProfile from '../../../components/userProfile/UserProfile';
import Feeds from '../../../components/feed/Feeds';

import { getItemById, getPostsByUser } from '../../../services/Crud';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader } from 'react-spinners';

const Account = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const [ loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState({});
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    handleGetUserById();
    if (selectedUser) {
      handleGetPostsByUser();
    }
  },[id]);

  const handleGetUserById = async () =>{
    try {
      const user = await getItemById('actors', parseInt(id));
      setSelectedUser(user);
      setPosts(user.articles);
      setLoading(false);
    } catch (error) {
      console.error(error.response.data.message);
      setLoading(false);
    }
  }

  const handleGetPostsByUser = async () =>{
    try {
      const articles = await getPostsByUser('articles/user', parseInt(id));
      setPosts(articles);
    } catch (error) {
      console.error(error.response.data.message);
    }
  }

  const backPage =() => {
    navigate(-1)
}
    return (
        <>
          {loading ? 
          <div className='loading'>
            <ClipLoader color="#36d7b7" />
          </div>
        :
        <>
          <div  onClick={backPage} style={{cursor:'pointer',margin:'20px',position:'absolute'}}>
            <FontAwesomeIcon icon={faArrowLeft}/>
            <span>Retour</span>
          </div>
            <UserProfile profil={selectedUser}/>
            {selectedUser.statut && 
                <>
                    <Separate profil={selectedUser}/>
                    <Feeds articles={posts} typeart="1"/>
                </>
            }
        </>
      }   
        </>
    );
};

export default Account;