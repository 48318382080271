import React from 'react';
// import './welcome.css'
import Footer from './footer/Footer'
import Header from './header/Header';
import About from './about/About';
import NavBar from './nav/NavBar';

const Welcome = () => {
    return (
        <div class="main-container">
            <NavBar/>
            <Header/>
            <About />
            <Footer/>
        </div>
    )
};

export default Welcome;
