import './chatbox.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight, faFileAlt } from '@fortawesome/free-solid-svg-icons'
// classe de messagerie
export default function ChatBox() {
  return (
    <>
      <div className="chatBox">
        <div className="chatBox-top">
          {/* <img src={CurrentUserData.map(user=>(user.ProfieImage))} alt="" /> */}
          <div className="user-name">
            <span></span>
                  {/* <h3>{CurrentUserData.map(user=>(user.name))}</h3>
                  <h5>{CurrentUserData.map(user=>(user.username))}</h5> */}
              </div>
        </div>
        <div className="chatBox-bottom">
          <form action="#">
            <input type="text" name="" id="" placeholder='Write Something' />
            <button type="submit" className='btn btn-primary'>
              <FontAwesomeIcon icon={faArrowAltCircleRight} />
            </button>
            <label className='btn btn-primary' htmlFor='FileAlt'>
              <input type="file" name="" id="FileAlt" />
              <FontAwesomeIcon icon={faFileAlt} />
            </label>
          </form>
        </div>
      </div>
    </>
  )
}
