import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthGuard from '@/services/auth/AuthGuard';
import PrivacyPolicy from '@/pages/public/faq/PrivacyPolicy';
import TeamsConditions from '@/pages/public/faq/TeamsConditions';

import Error from '@/_utils/Error';
import {Layourt, Welcome, Login, Signup, Verify} from '@/pages/public';
import Password from './login/password';
import VerifyPwd from './verifyAccount/VerifyPwd';
import ResetpwdForm from '../../components/forms/ResetpwdForm';
import Manual from './manual/Manual';
 
const PublicRouter = () => {
    return (
        <Routes>
          <Route element={<Layourt/>}>
            <Route index element={<Welcome/>}/>
            <Route path="/home" element={<Welcome />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/guide" element={<Manual />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
            <Route path="/teamsconditions" element={<TeamsConditions />} />
            <Route path="/forgetpwd" element={< Password/>} />
            <Route path="/verify/:email" element={<Verify/>}/>
            <Route path="/verifypwd/:email" element={<VerifyPwd/>}/>
            <Route path="/editpwd/:id" element={<AuthGuard><ResetpwdForm/></AuthGuard>} />
            <Route path="*" element={<Error/>}/>
          </Route>
        </Routes>
    );
};

export default PublicRouter;