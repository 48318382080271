import React, { useEffect, useState } from 'react';
import { activeAccount, deleteItem, getList } from '../../../services/Crud';
import './account.css'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faTrash } from '@fortawesome/free-solid-svg-icons'
import DeleteConfirmationModal from '../../../components/confirmModal/DeleteConfirmModal';
import LoadingButton from '../../../components/LodingButton/LoadingButton';
import { useNavigate } from 'react-router';
import { ClipLoader } from 'react-spinners';

const Accounts = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [ load, setLoad] = useState(true);
    const [isModal1Open, setIsModal1Open] = useState(false);
    const [elementToDelete, setElementToDelete] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [accountStatus, setAccountStatus] = useState({});
    const [filter, setFilter] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [accountsPerPage] = useState(8);

    const notifySuccess = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message);
    
    const handleActive = async (accountId) => {
        try {

            setLoading((prevLoading) => ({
                ...prevLoading,
                [accountId]: true,
            }));

            setAccountStatus((prevStatus) => ({
            ...prevStatus,
            [accountId]: !prevStatus[accountId],
            }));
            const updatedAccount = accounts.find((acc) => acc.id === accountId);
            if (updatedAccount) {
                updatedAccount.statut = !accountStatus[accountId];
                await activeAccount("actors/active-account", updatedAccount.id);
                handleGetAccounts();
                notifySuccess('Compte mis à jour avec succès.');
            }
        } catch (error) {
            setAccountStatus((prevStatus) => ({
                ...prevStatus,
                [accountId]: !prevStatus[accountId],
            }));
            notifyError("Une erreur s'est produite lors de la mise à jour du statut du compte.");
        }finally {
            setLoading((prevLoading) => ({
                ...prevLoading,
                [accountId]: false,
            }));
        }
    };
    
    useEffect(() => {
        handleGetAccounts();
    }, [accounts]);

    const handleGetAccounts = async () => {
        try {
          const list = await getList("actors");
          const statusMap = {};
          list.forEach(acc => {statusMap[acc.id] = acc.statut;});
          setAccounts(list);
          setAccountStatus(statusMap);
          setLoad(false);
        } catch (error) {
          console.error('Erreur lors du chargement des données :', error);
          setLoad(true)
        }
    }
    
    const filteredAccounts = accounts.filter(acc => {
        if (filter === 'active') {
            return acc.statut === true;
        } else if (filter === 'inactive') {
            return acc.statut === false;
        }
        return true;
    });

    // Pagination
    const indexOfLastAccount = currentPage * accountsPerPage;
    const indexOfFirstAccount = indexOfLastAccount - accountsPerPage;
    const currentAccounts = filteredAccounts.slice(indexOfFirstAccount, indexOfLastAccount);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    
    const openModal1 = (elementId) => {
        setElementToDelete(elementId);
        setIsModal1Open(true);
      };
    
    const closeModal1 = () => {
        setElementToDelete(null);
        setIsModal1Open(false);
    };
    
    const gotoAccount = (id) => {
        navigate(`/admin/account/${id}`);
    }
    
    const handleDelete = async () => {
        try {
            closeModal1();
            await deleteItem("actors", elementToDelete);
            setAccounts(filteredAccounts.filter(acc => acc.id !== elementToDelete));
        } catch (error) {
            notifySuccess('Erreur serveur');
        } finally {
            notifySuccess('Compte supprimé avec succès.');
        }
    }

    return (
        <div>

            {load ? 
                <div className='loading'>
                    <ClipLoader color="#36d7b7" />
                </div>
                :
                <>
                    <div className="acc-title">
                        <h5>Tous les acteurs</h5>
                    </div>
                    <hr />
                    <div className="filter-buttons">
                        <button
                            className={`filter-button ${filter === 'all' ? 'active' : ''}`}
                            onClick={() => setFilter('all')}
                        >
                            Tous
                        </button>
                        <button
                            className={`filter-button ${filter === 'active' ? 'active' : ''}`}
                            onClick={() => setFilter('active')}
                        >
                            Actifs
                        </button>
                        <button
                            className={`filter-button ${filter === 'inactive' ? 'active' : ''}`}
                            onClick={() => setFilter('inactive')}
                        >
                            Inactifs
                        </button>
                    </div>

                        <table>
                            <thead>
                            <tr>
                                <th scope="col">Structure</th>
                                <th scope="col">Region</th>
                                <th scope="col">Categorie</th>
                                <th scope="col">Maillon</th>
                                <th scope="col">Compte</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                                {currentAccounts.map(acc => (
                                    <tr key={acc.id}>
                                        <td data-label="Structure">{acc.name}</td>
                                        <td data-label="Region">{acc.region}</td>
                                        <td data-label="Categorie">{acc.categorie?.catName}</td>
                                        <td data-label="Maillon">{acc.profession}</td>
                                        <td data-label="Compte">{acc.statut === false ? "Inactif" : "Actif"}</td>
                                        <td data-label="" className="action">
                                        <div onClick={() => gotoAccount(acc.id)} ><FontAwesomeIcon icon={faInfo} className="pwd" title="Details"/></div>
                                            {!loading[acc.id] &&
                                                <div className="toggle-switch">
                                                    <input type="checkbox"
                                                        className="checkbox" name={acc.name} id={acc.name}
                                                        checked={accountStatus[acc.id]}
                                                        onChange={() => handleActive(acc.id)}
                                                    />
                                                    <label className="label" htmlFor={acc.name}>
                                                        <span className="inner" />
                                                        <span className="switch" />
                                                    </label>
                                                </div>
                                            }
                                            {loading[acc.id] && <LoadingButton color={"#36d7b7"} loading={loading}/>}
                                            <div onClick={() => openModal1(acc.id)} ><FontAwesomeIcon icon={faTrash} className="delete" title="Supprimer"/></div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    <div className="pagination">
                        {Array.from({ length: Math.ceil(filteredAccounts.length / accountsPerPage) }).map((_, index) => (
                        <button className="active" key={index} onClick={() => paginate(index + 1)}>
                            {index + 1}
                        </button>
                        ))}
                    </div>
                    <ToastContainer />
                    <DeleteConfirmationModal
                        isOpen={isModal1Open}
                        onClose={closeModal1}
                        onDelete={handleDelete}
                        title="Confirmation de suppression"
                        message="Voulez-vous vraiment supprimer cet acteur ?"
                    />
                </>
            }
        </div>
    );
};

export default Accounts;