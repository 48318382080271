import React from 'react';
import { Navigate } from 'react-router-dom';
import { authService } from './auth.service';

const AdminGuard = ({ children }) => {
    
    if (!authService.isAdmin()) {
        return <Navigate to="/login"/>;
    }
    return children;
};

export default AdminGuard;