import React, { useState, useRef, useEffect } from 'react'
import './feeds.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import profil from '../../assets/img/profil.png'
import { faComment, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Comments from '../comments/Comments'
import { authService } from '../../services/auth/auth.service';
import { jwtDecode } from 'jwt-decode';
import DeleteConfirmationModal from '../confirmModal/DeleteConfirmModal';
import { deleteItem } from '../../services/Crud';
import 'pure-react-carousel/dist/react-carousel.es.css';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import { Link } from 'react-router-dom';
import LimitText from './LimitText';
import 'react-awesome-slider/dist/styles.css';



const Feed = ({ post }) => {
    const sliderRef = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [elementToDelete, setElementToDelete] = useState(null);

    const [openComment, setOpenComment] = useState(false);
    const [admin, setAdmin] = useState(false);

    const [currentSlide, setCurrentSlide] = useState(0);

    const token = localStorage.getItem('token');
    const actorId = token ? jwtDecode(token).id : "";

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== null) {
            setAdmin(authService.isAdmin);
        }
    }, [])


    const phoneNumber = `237${post.acteur?.phoneOne}`;
    const message = "Salut !! J'ai trouvé votre profil sur la plate forme PDCVEP. J'aimerai en savoir plus à ce sujet"
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;

    const handleDelete = async () => {
        try {
            await deleteItem("articles", post.id);
        } catch (error) {
            console.error(`Erreur lors de la suppression : ${error.message}`);
        } finally {
            closeModal();
            window.location.href = window.location.href;
        }
    }

    const openModal = (id) => {
        setElementToDelete(id);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setElementToDelete(null);
        setIsModalOpen(false);
    };

    const handleComment = () => {
        setOpenComment(!openComment);
        console.log(elements);
    }
    const elements = post.images?.length;

    const formatDate = (date) => {
        // Options de formatage pour la date
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

        // Date actuelle
        const currentDate = new Date();

        // Date de publication
        const postDate = new Date(date);

        // Calcul de la différence de temps en secondes
        const timeDifferenceInSeconds = Math.floor((currentDate - postDate) / 1000); // Convertir les millisecondes en secondes

        // Constantes pour les conversions de secondes
        const secondsInMinute = 60;
        const secondsInHour = 3600;
        const secondsInDay = 86400;
        const secondsInTwoDays = 172800; // 24 heures * 2 (pour deux jours)


        if (timeDifferenceInSeconds < 60) {
            return "à l'instant"
        }
        // Condition pour "il y a X secondes"
        else if (timeDifferenceInSeconds < secondsInMinute) {
            return `il y a ${timeDifferenceInSeconds} seconde${timeDifferenceInSeconds > 1 ? 's' : ''}`;
        }
        // Condition pour "il y a X minutes"
        else if (timeDifferenceInSeconds < secondsInHour) {
            const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
            return `il y a ${minutes} minute${minutes > 1 ? 's' : ''}`;
        }
        // Condition pour "il y a X heures"
        else if (timeDifferenceInSeconds < secondsInDay) {
            const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
            return `il y a ${hours} heure${hours > 1 ? 's' : ''}`;
        }
        // Condition pour "hier à"
        else if (timeDifferenceInSeconds < secondsInTwoDays) {
            return `hier à ${postDate.toLocaleTimeString('fr-FR', { hour: 'numeric', minute: 'numeric' })}`;
        }
        // Condition pour la date complète
        else {
            return postDate.toLocaleDateString('fr-FR', options);
        }
    };


    const sliderConfig = {
        play: true,
        interval: 2000,
        bullets: elements > 1,
        organicArrows: true,
        fillParent: false,
        transitionDelay: 0,
        infinite: false,
        mobileTouch:elements > 1

    };
    const customButtonStyle = {
        background: 'blue',
        color: 'white',
        // Autres styles personnalisés
    };
    const customBulletStyle = (selected, index, onClick, onMouseEnter) => ({
        background: selected ? 'red' : 'gray',
        // Autres styles personnalisés
    });

    return (
        <>
        <div className='feed' key={post.id}>
            <div className="top-content">
                <Link to={`/members/profile/${post.acteur?.id}`}>
                    <div className="user">
                        {post.acteur?.imgProfile ? <img src={post.acteur?.imgProfile} alt="" /> : <img src={profil} alt="" />}
                        <div>
                            <h5>{post.acteur?.name}</h5>
                            <small>{formatDate(post.date)}</small>
                        </div>
                    </div>
                </Link>
                { (actorId === post.acteur?.id || admin) && <span onClick={() => openModal(post.id)}><FontAwesomeIcon title='Supprimer' className='deleteTrash' icon={faTrash} /></span>}
            </div>
            {/* <hr /> */}
            <div className="mid-content">
                <div className="vente-post">
                    <h5>{post.title}</h5>
                    {post.type === "vente" && <span className='post-vente'>{post.type}</span>}
                </div>
                <div className="mid-content-text">
                    <p>

                        {/* <p className="desc-center">{post.description}</p> */}
                        <LimitText limit={500} text={post.description} />
                    </p>
                </div>
                {
                    (elements > 0) &&
                    <>
                        <div className='theSlider'>
                            <AwesomeSlider
                                {...sliderConfig}
                                organicArrows
                            >
                                {
                                    post.images.map(img => (
                                        <div key={img.id} >
                                            <img src={img.base64Image} alt='photos' />
                                        </div>
                                    ))
                                }
                            </AwesomeSlider>
                        </div>
                    </>
                }
            </div>
            <div className="bottom-content">
                <div className="action-item">
                    <span className='coment-icon' onClick={handleComment}><FontAwesomeIcon icon={faComment} />Commentaires ({post.coments?.length})</span>
                    {post.type === "vente" &&
                        <Link to={whatsappUrl} target="_blank" rel="noopener noreferrer" title="Contacter">
                            <button className="btn-wats">
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: 'white' }} height="20" width="18" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg>
                                {/* <span>(+237) {post.actor?.phoneOne}</span> */}
                            </button>
                        </Link>
                    }
                </div>
            </div>
            {
                openComment && <Comments post={post} />
            }
        </div>

        <DeleteConfirmationModal
            isOpen={isModalOpen}
            onClose={closeModal}
            onDelete={handleDelete}
            message="Voulez-vous vraiment supprimer cet article ?"
        />
    </>
    )
}
export default Feed;