
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AdminGuard from '@/services/auth/AdminGuard';
import AuthGuard from '@/services/auth/AuthGuard';
import MembersRouter from './pages/members/MembersRouter';
import PublicRouter from './pages/public/PublicRouter';
import AdminRouter from './pages/admin/AdminRouter';
import NoAdminGuard from './services/auth/NoAdminGuard';

function App() {
  
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<PublicRouter />} />
          <Route path="/members/*" element={
            <NoAdminGuard>
              <MembersRouter/>
            </NoAdminGuard>
          } />
          <Route path="/admin/*" element={
            <AuthGuard>
              <AdminGuard>
                <AdminRouter/>
              </AdminGuard>
           </AuthGuard>
          } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
