import React from 'react';
import '../components/forms/accountForm.css'

const Error = () => {
    return (
        <div className="Error">
            Erreur 404 !
        </div>
    );
};

export default Error;