import './feeds.css'
import Feed from './Feed'
import { useState } from 'react';

const Feeds = ({ articles, typeart }) => {

  const [type, setType] = useState("");
  const [arrdissement, setArrdissement] = useState("");

  const arrondWithUsers = Array.from(new Set(articles.map(article => article.acteur?.arrdissement)));

  // Filtrer les articles en fonction des sélections de l'utilisateur
  const filteredArticles = articles
    .filter((article) => !type || article.type === type) // Filtrer par type
    .filter((article) => !arrdissement || article.acteur?.arrdissement === arrdissement); // Filtrer par arrondissement

  return (
    <>
      {/* La barre de filtre */}
      <div className="filter-bar">
        <select id="" value={type} onChange={(e) => setType(e.target.value)}>
        <option value="">Tous les articles</option>
          <option value="vente">Ventes</option>
          <option value="annonce">Annonces</option>
        </select>
        {typeart == 0 && 
          <select id="" value={arrdissement} onChange={(e) => setArrdissement(e.target.value)}>
            <option value="">Arrondissements</option>
            {arrondWithUsers.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </select>
        }
      </div>

      {/* Le bouton flottant */}
      {/* {authService.isLogged() && <Float />} */}

      {/* Tous les feeds filtrés */}
      {filteredArticles.map((post) => (
        <Feed post={post} key={post.id} />
      ))}
    </>
  );
};

export default Feeds;
