import './leftBar.css'
import { Link } from 'react-router-dom'
import profile from '../../assets/img/profil.png';
import Friend from '../../assets/icons/1.png'
import Market from '../../assets/icons/3.png'
import { useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import { authService } from '../../services/auth/auth.service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'

export default function LeftBar() {

  const [userName, setUserName] = useState();
  const [userId, setUserId] = useState();

  useEffect(() => {
    if (authService.isLogged()) {
      setUserId(jwtDecode(authService.getToken()).id);
      setUserName(jwtDecode(authService.getToken()).name);
    }
    // setUserName(jwtDecode(localStorage.getItem('token')).sub);
    // setUserId(jwtDecode(localStorage.getItem('token')).id);
  }, [])

  return (
    <div className='leftBar'>
      <div className="left-container">
        <div className="menu">
          {/* {userName ? 
            <Link to={`profile/${userId}`}>
            <div className="user">
              <img src={profile} alt="" />
              <h4>{userName}</h4>
            </div>
          </Link> : <></>
          } */}

          <Link to=''>
            <div className="item">
              <img src={Friend} alt="" />
              <h4>Articles</h4>
            </div>
          </Link>
          <Link to='actors'>
            <div className="item">
              <FontAwesomeIcon icon={faBuilding} />
              <h4>Acteurs</h4>
            </div>
          </Link>
        </div>
        <hr />
      </div>
    </div>
  )
}
