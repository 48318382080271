    // Maillon de production pisciculture
    export const piscicultureOption = [
        { value: 'producteur-de-poisson', label: 'Producteur de poisson' },
        { value: 'producteur-d-alevins', label: 'Producteur d\'alevins' },
        { value: 'commercant', label: 'Commerçant' },
        { value: 'producteur-d-aliments', label: 'Producteur d\'aliments' },
        { value: 'transformateur', label: 'Transformateur' },
    ];

    export const porcOption = [
        { value: 'elevage-porcelet', label: 'Élevage porcelet' },
        { value: 'elevage-porcs', label: 'Élevage porcs' },
        { value: 'commercant', label: 'Commerçant' },
        { value: 'provendier', label: 'Provendier' },
        { value: 'transformateur', label: 'Transformateur' },
      ];

      export const bovinOption = [
        { value: 'elevage', label: 'Élevage' },
        { value: 'commercant', label: 'Commerçant' },
        { value: 'transformateur', label: 'Transformateur' },
      ];

    // types d'infrastiture
    export const infrastructureOptions = [
        { value: 'etangs', label: 'Étangs' },
        { value: 'hors-sol', label: 'Hors sol' },
        { value: 'circuit-ferme', label: 'Circuit fermé' },
        { value: 'circuit-ouvert', label: 'Circuit ouvert' },
    ];

    export const infrastructureBovinOptions = [
      { value: 'etable', label: 'Etable' },
      { value: 'parcours-naturel', label: 'Parcours naturel' },
  ];
    
    export const speciesOptions = [
        { value: 'silure', label: 'Silure' },
        { value: 'tilapia', label: 'Tilapia' },
        { value: 'carpe', label: 'Carpe' },
        { value: 'kanga', label: 'Kanga' },
        { value: 'poisson-vipere', label: 'Poisson vipère' },
    ];
    
    // Type de batiment
    export const batimentgOptions = [
        { value: 'provisoire', label: 'Provisoire' },
        { value: 'definitif', label: 'Définitif' },
      ];
    
    // Type de race
      export const raceOptions = [
        { value: 'Naima', label: 'Naima' },
        { value: 'large-white', label: 'Large White' },
        { value: 'landrace', label: 'Landrace' },
        { value: 'pietran', label: 'Pietran' },
        { value: 'tropic', label: 'Tropic' },
        { value: 'Duroc', label: 'Duroc' },
        { value: 'hybride', label: 'Hybride' },
      ];

      export const raceBovinOptions = [
        { value: 'Mobroro', label: 'Mbororo' },
        { value: 'wakwa', label: 'Wakwa' },
        { value: 'goudali', label: 'Goudali' },
        { value: 'aku', label: 'Aku' },
        { value: 'kapsiki', label: 'Kapsiki' },
        { value: 'taurin', label: 'Taurin' },
        { value: 'namchi', label: 'Namchi' },
        { value: 'holstain', label: 'Holstain' },
      ];
    
    // Type de production
      export const productionOptions = [
        { value: 'elevage-naisseur', label: 'Élevage naisseur' },
        { value: 'engraisseur', label: 'Engraisseur' },
        { value: 'naisseur-engraisseur', label: 'Naisseur-engraisseur' },
      ];