import React, { useState } from 'react'
import './login.css'
import { Link, useNavigate } from 'react-router-dom'
import { createItem } from '../../../services/Crud'
import { authService } from '../../../services/auth/auth.service'
import LoadingButton from '@/components/LodingButton/LoadingButton'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Login = () => {

    const navigate = useNavigate();
    const [err, setErr] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    
    const backPage =() => {
        navigate(-1)
    }

    const AuthUser = {
        username: '',
        password: ''
    }

    const [authUser, setAuthUser] = useState(AuthUser);
    
    const handleChange = (e) => {
        setAuthUser({ ...authUser, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            await createItem('users/login', authUser)
                .then(res => {
                    authService.saveToken(res.accessToken);
                    if (authService.isAdmin()) {
                        navigate('/admin');
                    } else {
                        navigate('/members');
                    }
                })
        } catch (error) {
            setErr(true);
            setErrorMessage(error.response.data.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            {/* <NavBar/> */}
            <div  onClick={backPage} style={{cursor:'pointer',margin:'20px',position:'absolute'}}>
            <FontAwesomeIcon icon={faArrowLeft}/>
            <span>Retour</span>
            </div>
            <div className="l-body">
                <div className="login-container">
                    <div className="l-title">
                        <h3>Se connecter</h3>
                        <hr />
                        {err && (
                            <span className='error-message'>{errorMessage}</span>
                        )}
                    </div>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input type="text" name='username' value={authUser.username} onChange={handleChange} id="login" required />
                                <label htmlFor="login">Email</label>
                                <span className="icon">&#128100;</span>
                            </div>
                            <div className="form-group">
                                <input type="password" name='password' value={authUser.password} onChange={handleChange} id="password" required/>
                                <label htmlFor="password">Mot de passe</label>
                                <span className="icon">&#128274;</span>
                            </div>
                        {!loading && <button type="submit" className="submit-btn">Connexion</button>}
                        {loading && <button className="loading-btn"><LoadingButton color={"#fff"} loading={loading}/></button>}
                        <span className='forgetpwd'><Link to="/forgetpwd">Mot de passe oublié ?</Link></span>
                        </form>
                        <div className="create-account">
                            <p>Vous n'avez pas de compte ? <Link to="/signup">Creer un compte</Link></p>
                        </div>
                    </div>
            </div>
           
            {/* <Footer/> */}
        </>
    )
}

export default Login
