import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { sidebarData } from '../SideBar/SideBar';
import profile from '../../../assets/img/profil.png';
import './navBar.css'
import LOGO from '../../../assets/icons/theLogo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import DropDown from '../../../components/dropDown/DropDown';
import { authService } from '../../../services/auth/auth.service';

const NavBar = () => {
    const userName = authService.getUserName();
    const [sidebar, setSidebar] = useState(false);
    const showSideBar = () => setSidebar(!sidebar)

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const handleMouseEnter = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

    return (
        <>
            <nav>
                <div className="a-nav-container">
                    <div className="a-nav-left">
                    <Link to='/'>
                        <h3 className="a-logo">
                        <img src={LOGO} alt="" />
                        </h3>
                    </Link>
                    {/* <Link to='#'>
                        <FontAwesomeIcon icon={faBars} onClick={showSideBar}/>
                    </Link> */}
                    </div>
                    <div className="nav-right">
                        <div className="user">
                            <img src={profile} alt="" />
                            <h4 className="userName"> {userName}</h4>
                            <FontAwesomeIcon icon={faAngleDown} onClick={handleMouseEnter}/>
                        </div>
                        {/* {isDropdownVisible && <div className='dropDown'><DropDown/></div> } */}
                        {isDropdownVisible && (<div ref={dropdownRef} className="dropDown"><DropDown /></div>)}
                    </div>
                </div>
            </nav>
            <section className={sidebar ? 'a-nav-menu active' : 'a-nav-menu'}>
                <ul className='a-nav-menu-items'>
                    {sidebarData.map((item, index) => (
                        <li key={index} className={item.cName}>
                            <Link to={item.path} onClick={showSideBar}>
                                {item.icon}
                                <span>{item.title}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </section>
        </>
    );
};

export default NavBar;
