import React, { useState } from 'react'

export default function LimitText({text,limit}) {
    const [fullText, setFullText] = useState(false)

    const showText=fullText ? text : text.slice(0,limit);
  return (
    <div onClick={()=>setFullText(!fullText)}>
        {showText} 
        {
            text.length > limit && (
                <button onClick={()=>setFullText(!fullText)} style={{cursor:'pointer',background:'none',color:'GrayText',fontWeight:'bolder',marginLeft:'10px'}}>
                    {
                        fullText ? '...  Voir Moins' : '...  Voir Plus'
                    }
                </button>
            )
        }
    </div>
  )
}
