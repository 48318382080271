
import Feeds from '../../../components/feed/Feeds'
import { useEffect, useRef, useState } from 'react';
import { getList } from '../../../services/Crud';
import { authService } from '../../../services/auth/auth.service';
import Float from '../../../components/floatButton/Float';
import { ClipLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import './home.css'

export default function Home() {
  const dropdownRef = useRef(null);
  const [posts, setPosts] = useState([]);
  const [posts0, setPosts0] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [ loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  useEffect(() => {
      handleGetAll();
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
  },[])

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSearch(false);
    }
  };

  const handleGetAll = async () => {
      try {
        const data = await getList('articles');
          setPosts(data);
          setLoading(false);
      } catch (error) {
        console.log(error);
        setErrorMessage(error.message !== null ? error.message : error.response.data.message);
        setLoading(false);
      }
  }

  const showSearchForm = () =>{
    setSearch(!search);
  }
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
};

  const filteredPosts = posts.filter(post =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase())
);

const showArticle = (art) => {
  setSelectedArticle(art);
  setSearch(!search);
}

// Méthode pour filtrer les articles en fonction du mot-clé du titre de l'article sélectionné
const filterArticles = () => {
  if (!selectedArticle) {
    return posts;
  }
  const selectedArticleText = selectedArticle.title;
  const keywords = selectedArticleText.split(' ');
  const keyword = keywords.length >= 3 ? keywords[2] : (keywords.length >= 2 ? keywords[1] : keywords[0]);
  return posts.filter(article => article.title.includes(keyword));
};



const resetAll = () => {
  setSelectedArticle(null);
}

  return (
    <>
      <div className='searchIcon'>
          <FontAwesomeIcon icon={faSearch} onClick={showSearchForm}/>
      </div>
      <div className='homeIcon'>
          <FontAwesomeIcon icon={faHome} onClick={resetAll}/>
      </div>
      { search && 
        <div className='seachBox' ref={dropdownRef}>
        <div className="messageSearch">
            <FontAwesomeIcon icon={faSearch} />
            <input
                type="search"
                placeholder='Rechercher un profil'
                value={searchTerm}
                onChange={handleSearchChange}
            />
        </div>
        <div className="border-div"></div>
          {filteredPosts.map(post => (
              <div key={post.id} onClick={() => showArticle(post)}>
                  <div className="message">
                      <div className="message-body">
                          <h5>{post.title}</h5>
                      </div>
                  </div>
              </div>
          ))}
      </div>
      }
      
      {loading ? 
          <div className='loading'>
            <ClipLoader color="#36d7b7" />
          </div>
        :
        <>
          {authService.isLogged() && <Float />}
          <Feeds articles={filterArticles()} typeart="0"/>
        </>
      }
      
    </>
  )
}