import './nav.css'
import { Link } from 'react-router-dom'
import profile from '../../assets/img/profil.png';
import { useEffect, useRef, useState } from 'react';
import LOGO from '../../assets/icons/theLogo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faEllipsisVertical} from '@fortawesome/free-solid-svg-icons'
import { authService } from '../../services/auth/auth.service';
import { jwtDecode } from 'jwt-decode';

export default function Nav() {
    const isLogged = authService.isLogged();
    const dropdownRef = useRef(null);
    const btnRef = useRef(null);
    const menuRef = useRef(null);
    const [userId, setUserId] = useState();

    const logout = () => {
      authService.logout();
      window.location.href = window.location.href;
    }
    
    const [isDropdownVisible,setIsDropdownVisible]=useState(false);
    const toggleMenu=()=>{
      setIsDropdownVisible(!isDropdownVisible)
    }

    const [burgerMenuOpen,setBurgerMenuOpen]=useState(false);
    const toggleBurgerMenu=()=>{
      setBurgerMenuOpen(!burgerMenuOpen)
    }

    const closeDropdown = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
        }
      };
      
  useEffect(() => {
      if (authService.isLogged()) {
        setUserId(jwtDecode(authService.getToken()).id);
      }
      
    const handleClickOutside = (event) => {
      if (
        isDropdownVisible &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        btnRef.current &&
        !btnRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);

      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownVisible]);

  return (
    <nav>
      
      <div className="nav-container">
        <div className="nav-left">
          <Link to='/'>
            <h3 className="members-logo">
              <img src={LOGO} alt="" />
            </h3>
          </Link>
          <FontAwesomeIcon icon={faBars} className='a-icon burger' onClick={toggleBurgerMenu}/>
          
          {/* <div className='faSearch'>
            <div className='a-icon'>
              <FontAwesomeIcon icon={faSearch} onClick={showSearchForm}/>
            </div>
          </div> */}
        </div>
                   {/* .................... Barre de Droite .............. */}
        <div className="nav-right">
          
          <div className="rigth-action">
            {!isLogged && 
              <div className="sing-log">
              <Link to="/login" class="btn-sign">Connexion</Link>
              <Link to="/signup" class="btn-sign">Inscription</Link>
            </div>
            }
            {isLogged && 
              <div className="user"  ref={dropdownRef}>
                  <div onClick={toggleMenu} ref={btnRef} style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'3em'}}>

                  <img src={profile} alt=""  />
                  <FontAwesomeIcon className='a-icon' icon={faEllipsisVertical} />
                  </div>
                  {isDropdownVisible && <div className='m_dropdown'>
                  <div className="dropdown-menu" ref={menuRef}>
                      <ul className="ul-menu">
                        <Link to={`/members/profile/${userId}`}><li onClick={toggleMenu}>Mon compte</li></Link>
                          
                          <li onClick={logout}>Deconnexion</li>
                      </ul>
                  </div>
                  </div> }
              </div>
            }
          </div>
        </div>
      </div>
      {burgerMenuOpen && 
      <div className='b_dropdown'  ref={dropdownRef}>
        <div className="dropdown-menu">
          <Link to="home" onClick={toggleBurgerMenu}>
              <div>Articles</div>
          </Link>
          <Link to="actors"  onClick={toggleBurgerMenu}>
              <div>Acteurs</div>
          </Link>
        </div>
      </div> }
    </nav>
  )
}