import React, { useEffect, useState } from 'react';
import './message.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import profil from '../../assets/img/profil.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from 'jwt-decode';

const Message = ({ accounts }) => {
    const currentPath = window.location.pathname;
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [userId, setUserId] = useState();

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== null) {
            setUserId(jwtDecode(token).id);
        }
    }, [])

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const allAccounts = accounts.filter(acc => acc.id !== userId);

    const filteredAccounts = allAccounts.filter(user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const goToProfil = (id) => {
        navigate(`/members/profile/${parseInt(id)}`);
    };

    const handleRegionChange = (region) => {
        navigate(`/members/council/${region}`);
    };

    const regionsWithUsers = Array.from(new Set(accounts.map(user => user.region)));

    return (
        <div className='Messages'>
            {(currentPath !== "/members/actors" && !currentPath.startsWith("/members/council")) && (
                <>
                    {/* <div className="message-top">
                        <h4>Acteurs</h4>
                    </div> */}
                    <div className="messageSearch">
                        <FontAwesomeIcon icon={faSearch} />
                        <input
                            type="search"
                            placeholder='Rechercher un profil'
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <div className="border-div"></div>
                    {filteredAccounts.map(user => (
                        <div className={currentPath === `/members/profile/${user.id}` ? "profil s_active" : "profil"} onClick={() => goToProfil(user.id)} key={user.id}>
                            <div className="message">
                                <div className="user">
                                    {user.imgProfile ? <img src={user.imgProfile} alt="" /> : <img src={profil} alt="" />}
                                </div>
                                <div className="message-body">
                                    <h5>{user.name}</h5>
                                    <p className='overflow-ellipsis'>{user.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}

            {(currentPath === "/members/actors" || currentPath.startsWith("/members/council")) && (
                <div className="menu">
                    <div>
                        <span>Régions</span>
                    </div>
                    <hr />
                    <div className="regions-container">
                        <Link to='/members/actors'><ul className={currentPath === `/members/actors` ? "active" : ""}><li>Toutes régions</li></ul></Link>
                        {regionsWithUsers.map(region => (
                            <ul
                                onClick={() => handleRegionChange(region)}
                                key={region}
                                className={currentPath === `/members/council/${region}` ? "active" : ""}
                            >
                                <li>{region}</li>
                            </ul>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Message;
