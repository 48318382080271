import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Account from './account/Account';
import Accounts from './accounts/Accounts';
import ALayourt from './ALayourt';

const AdminRouter = () => {
    return (
        <Routes>
            <Route element={<ALayourt />} >
                <Route index element={<Accounts />} />
                <Route path="/accounts" element={<Accounts />} />
                <Route path="/account/:id" element={<Account />} />
            </Route>
       </Routes>
    );
};

export default AdminRouter;