import React from 'react'
import './faq.css'

export default function Footer() {
  return (
    <div className='faq-content'>   

        <h1>Conditions Générales d'Utilisation</h1>

        <p>Ces Conditions Générales d'Utilisation ("CGU") régissent l'utilisation de notre application par les utilisateurs.</p>

        <h2>Acceptation des CGU</h2>

        <p>
            En utilisant notre application, vous acceptez d'être lié par ces CGU. Si vous n'acceptez pas ces CGU, vous
            ne pouvez pas utiliser notre application.
        </p>

        <h2>Utilisation de l'Application</h2>

        <p>
            Vous acceptez d'utiliser notre application conformément à ces CGU, ainsi qu'à toutes les lois, règles et
            réglementations applicables. Vous acceptez de ne pas utiliser notre application de manière abusive, illégale,
            frauduleuse ou contraire à ces CGU.
        </p>

        <h2>Droits de Propriété Intellectuelle</h2>

        <p>
            Notre application et tout son contenu, y compris mais sans s'y limiter, les textes, les graphiques, les logos,
            les icônes, les images, les clips audio, les vidéos, les bases de données, les logiciels, les codes et les marques
            de commerce, sont la propriété de AR1/PDCVEP et sont protégés par les lois sur la propriété intellectuelle applicables.
        </p>

        <h2>Responsabilité et Limitation de Responsabilité</h2>

        <p>
            Nous ne sommes pas responsables des dommages, pertes ou préjudices causés par l'utilisation de notre application,
            sauf dans les cas de négligence grave ou de faute intentionnelle. Dans toute la mesure permise par la loi, notre
            responsabilité envers vous est limitée aux dommages directs et prévisibles.
        </p>

        <h2>Modifications des CGU</h2>

        <p>
            Nous nous réservons le droit de modifier ces CGU à tout moment. Les utilisateurs seront informés des modifications
            apportées à ces CGU par le biais d'une notification dans notre application. L'utilisation continue de notre
            application après la publication de modifications de ces CGU constitue l'acceptation de ces modifications par
            les utilisateurs.
        </p>

        <h2>Contactez-nous</h2>

        <p>Si vous avez des questions ou des préoccupations concernant ces CGU, veuillez nous contacter à <a href="mailto:etiennewedjou@yahoo.com">etiennewedjou@yahoo.com</a>.</p>

    </div>
  )
}
