import axiosInstance from "./auth/Interceptors";

const BASE_URL = "";

const fetchData = async (config) => {
  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fonction générique pour créer un élément
export const createItem = (endpoint, item) => {
  const config = {
    method: 'post',
    url: `${BASE_URL}/${endpoint}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: item,
  };

  return fetchData(config);
}

// Fonction générique pour mettre à jour un élément
export const updateItem = (endpoint, id, item) => {
  const config = {
    method: 'put',
    url: `${BASE_URL}/${endpoint}/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: item,
  };

  return fetchData(config);
}

// Fonction générique pour mettre à jour partiellement un élément
export const partialUpdateItem = (endpoint, id, partialItem) => {
  const config = {
    method: 'patch',
    url: `${BASE_URL}/${endpoint}/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: partialItem,
  };

  return fetchData(config);
}

// Fonction générique pour obtenir un élément par son ID
export const getItemById = (endpoint, id) => {
  const config = {
    method: 'get',
    url: `${BASE_URL}/${endpoint}/${id}`,
  };

  return fetchData(config);
}

// Fonction générique pour obtenir la liste des éléments
export const getList = (endpoint) => {
  const config = {
    method: 'get',
    url: `${BASE_URL}/${endpoint}`,
  };

  return fetchData(config);
}

// Fonction générique pour supprimer un élément par son ID
export const deleteItem = (endpoint, id) => {
  const config = {
    method: 'delete',
    url: `${BASE_URL}/${endpoint}/${id}`,
  };
  return fetchData(config);
}

export const getPostsByUser = (endpoint, id) => {
  const config = {
    method: 'get',
    url: `${BASE_URL}/${endpoint}/${id}`,
  };

  return fetchData(config);
}

export const getComentByArticle = (endpoint, id) => {
  const config = {
    method: 'get',
    url: `${BASE_URL}/${endpoint}/${id}`,
  };

  return fetchData(config);
}

export const resetPassword = (endpoint, id) => {
  const config = {
    method: 'get',
    url: `${BASE_URL}/${endpoint}/reset-pwd/${id}`,
  };

  return fetchData(config);
}

export const verifieAccount = (endpoint, email, otp) => {
  const config = {
    method: 'put',
    url: `${BASE_URL}/${endpoint}/verify-account?email=${email}&otp=${otp}`,
  };
  return fetchData(config);
}

export const verifyPwdOtp = (endpoint, email, otp) => {
  const config = {
    method: 'put',
    url: `${BASE_URL}/${endpoint}/verify-pwdotp?email=${email}&otp=${otp}`,
  };
  return fetchData(config);
}

export const generateOtp = (endpoint, email) => {
  const config = {
    method: 'put',
    url: `${BASE_URL}/${endpoint}/generate-otp?email=${email}`,
  };
  return fetchData(config);
}

export const activeAccount = (endpoint, id) => {
  const config = {
    method: 'get',
    url: `${BASE_URL}/${endpoint}/${id}`,
  };
  return fetchData(config);
}
