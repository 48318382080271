import React, { useEffect, useState } from 'react';
import { getList } from '../../../services/Crud';
import profile from '../../../assets/img/profil.png';
import './users.css';
import { Link, useNavigate } from 'react-router-dom';
import FilterBar from '../../../components/filterCategories/FilterBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader } from 'react-spinners';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Tous');
  const [filterIsOpen,setFilterIsOpen]=useState(false);
  const [ loading, setLoading] = useState(true);


  const handleOpenFilter=()=>{
    setFilterIsOpen(!filterIsOpen)
  }

  const regionsWithUsers = Array.from(new Set(users.map(user => user.region)));

  useEffect(() => {
    handleGetAllUsers();
  }, [selectedCategory]);

  const handleGetAllUsers = async () => {
    try {
      const list = await getList('actors');
      setUsers(list.filter(acc => acc.statut === true));
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors du chargement des données :', error);
      setLoading(false);
    }
  };

  const currentPath = window.location.pathname;
  const navigate = useNavigate();
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleRegionChange = (region) => {
    navigate(`/members/council/${region}`);
  }

  const filteredUsers = selectedCategory === 'Tous'
    ? users
    : users.filter(acc => acc.categorie && acc.categorie?.catName === selectedCategory);

  return (
    <>
      {loading ? 
          <div className='loading'>
            <ClipLoader color="#36d7b7" />
          </div>
        :
        <>
            <div>
              <h4>Tous les acteurs</h4>
            </div>
          <div className="tiltle">
            <div className='filter' onClick={handleOpenFilter}>
              <FontAwesomeIcon icon={faFilter} title='filtrer'/>
            </div>
            {filterIsOpen && 
            (
              <div className="filter_menu">
                <div>
                    <span>Filtrer par</span>
                </div>
                <hr />
                <div className="regions-container">
                  <Link to='/members/actors'><ul className={currentPath === `/members/actors` ? "active" : ""}><li>Toutes régions</li></ul></Link>
                    {regionsWithUsers.map(region => (
                        <ul onClick={() => handleRegionChange(region)} key={region} 
                            className={currentPath === `/members/council/${region}` ? "active" : ""}>
                            <li>
                                {region}
                            </li>
                        </ul>
                    ))}
                </div>
              </div>
            )}
          </div>
          <FilterBar onFilterChange={handleCategoryChange} />
          {filteredUsers.map((user) => (
            <div key={user.id} className="car-body">
                <div className="img_pod">
                  {user.imgProfile ? <img className="card-img" src={user.imgProfile} alt="Logo" /> : <img src={profile} alt="Logo" />}
                </div>
                <div className="container_copy">
                  <div className='u_name'>
                    {user.name}
                  </div>
                  <div className='u_council'>
                    <p>Région: <em>{user.region}</em></p>
                  </div>
                  <hr/>
                  <div className='u_desc'>
                    <p>{user.description}</p>
                  </div>
                  <div className='more'>
                    <div>
                      <Link className="btn_primary" to={`/members/profile/${user.id}`}>
                        Voir plus
                      </Link>
                    </div>
                  </div>
                </div>
            </div>
          ))}
        </>
      }
      
    </>
  );
};

export default Users;
