import React from 'react'
import './separate.css'


export default function Separate({profil}) {

  const counter=profil.articles?.length;
  return (
    <>
    {
      counter > 0 && 
    <div className='separate'>
      <div>Publications</div>
      <div className='counter'>{counter}</div>
    </div>
    }
    {
      counter === 0 && 
    <div className='separate'>
      Aucune Publication
    </div>
    }
    </>
  )
}
