import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '@/components/public/Header';


const Layourt = () => {
    return (
        <>
            {/* <Header /> */}
            <Outlet/>
        </>
    );
};

export default Layourt;