import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MLayourt from './MLayourt';
import Home from './home/Home';

import Error from '@/_utils/Error';
import ChatBox from '@/pages/members/chatbox/Chatbox';
import Profile from '@/pages/members/profile/Profile';
import UsersByCouncil from '@/pages/members/usersByCouncil/UsersByCouncil';
import EditProfil from './profile/EditProfil';
import AuthGuard from '@/services/auth/AuthGuard';
import Users from './users/Users';
import ResetpwdForm from '../../components/forms/ResetpwdForm';

const MembersRouter = () => {
    return (
        <Routes>
            <Route element={<MLayourt />}>
                <Route index element={<Home/>} />
                <Route path="/home" element={<Home />} />
                <Route path="/actors" element={<Users />} />
                <Route path="/chatbox/:id" element={<ChatBox />} />
                <Route path="/profile/:id" element={<Profile />} />
                <Route path="/profile/edit/:id" element={<AuthGuard><EditProfil/></AuthGuard>} />
                <Route path="council/:cl" element={<UsersByCouncil />} />
                <Route path="*" element={<Error/>}/>
            </Route>
       </Routes>
    );
};

export default MembersRouter;