import React, { useState } from 'react';
import Nav from '@/components/nav/Nav'
import './resetpwdForm.css'
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {updateItem } from '../../services/Crud';
import LoadingButton from '@/components/LodingButton/LoadingButton'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetpwdForm = () =>{

    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [resetpwdMsg, setResetpwdMsg] = useState('');
    const [err, setErr] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [formInput, setFormInput] = useState({
        lastPwd: "",
        password: "",
        confirmPwd: "",
    })

    const [formError, setFormError] = useState({
        lastPwd: "",
        password: "",
        confirmPwd: "",
    })

    const backPage =() => {
        navigate(-1)
    }

    const handleInputChange = (name, value) => {
        setFormInput ( {...formInput, [name]: value});
    }

    const validateFomInput = async (e) => {
        e.preventDefault();

        // Initialize an object to track input errors
        let inputError = {
            lastPwd: "",
            password: "",
            confirmPwd: "", 
        };

        if (formInput.confirmPwd !== formInput.password) {
            setFormError({
            ...inputError,
            confirmPwd: "Les deux mots de passe doivent corespondre"
            });
            return;
        }
        if (!formInput.password){
            setFormError({
           ...inputError,
           password: "Le mot de passe ne doit pas être vide",
           });
           return;
       }
        
        if (!formInput.lastPwd){
             setFormError({
            ...inputError,
            lastPwd: "Le mot de passe ne doit pas être vide",
            });
            return;
        }

        if (formInput.password.length < 6){
            setFormError({
           ...inputError,
           password: "Le mot de passe doit contenir au moins 6 charactères",
           });
           return;
       }

        try {
            setLoading(true);
            await updateItem('actors/reset-pwd-byacteur', parseInt(id), formInput)
            .then(res => {
                if (res !== 2) {
                    toast.success(res);
                    window.history.back();
                }
                setResetpwdMsg("Mot de passe actuel incorrect");
            })
          } catch (error) {
            setErr(true);
            setErrorMessage(error.response.data.message);
          }finally {
            setLoading(false);
        } 
       
    }

    return (
        <>
            <Nav/>
            <div  onClick={backPage} style={{cursor:'pointer',margin:'20px',position:'absolute'}}>
            <FontAwesomeIcon icon={faArrowLeft}/>
            <span>Retour</span>
            </div>
            <div className='pwd-container'>
                <div className='pwd-card'>
                    <div className='pwd-card-header'>
                        <h4>Modifier mot de passe</h4>
                    </div>
                    <div className='pwd-card-body'>
                        {err && (<span className='error-message'>{errorMessage}</span>)}
                        <form onSubmit={validateFomInput}>
                        <p className='pwd-label'>Mot de passe actuel</p>
                            <input
                                value={formInput.lastPwd}
                                onChange={({target}) => {handleInputChange(target.name, target.value)}}
                                name="lastPwd"
                                type="password"
                                className='pwd-input'
                                placeholder="Nouveau mot de passe"
                            />
                            {resetpwdMsg === '' && <p className='pwd-err-message'>{formError.lastPwd}</p>}
                            {resetpwdMsg !== '' && (<p className='pwd-err-message'>{resetpwdMsg}</p>)}

                        {/* pwd */}
                            <p className='pwd-label'>Nouveau mot de passe</p>
                            <input
                                value={formInput.password}
                                onChange={({target}) => {handleInputChange(target.name, target.value)}}
                                name="password"
                                type="password"
                                className='pwd-input'
                                placeholder="Nouveau mot de passe"
                            />
                            {!formError.password && <em className='pwd-success-message'>Minimum 6 charactères</em>}
                            {resetpwdMsg === '' && <p className='pwd-err-message'>{formError.password}</p>}

                        {/* confirmpwd */}
                            <p className='pwd-label'>Confirmer mot de passe</p>
                            <input
                                value={formInput.confirmPwd}
                                onChange={({target}) => {handleInputChange(target.name, target.value)}}
                                name="confirmPwd"
                                type="password"
                                className='pwd-input'
                                placeholder="Confirmer le mot de passe"
                            />
                            {resetpwdMsg === '' && <p className='pwd-err-message'>{formError.confirmPwd}</p>}
                            {!loading && <button type="submit" className="btn-pwd">Modifier</button>}
                            {loading && <button className="loading-btn"><LoadingButton color={"#fff"} loading={loading}/></button>}
                        </form>
                    </div>
                </div>
            </div>
        </>
        
    )
}
export default ResetpwdForm;