import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './post.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faImages, faTimesCircle, faUsd } from '@fortawesome/free-solid-svg-icons';
import { authService } from '../../../services/auth/auth.service';
import { createItem, getItemById } from '../../../services/Crud';
import { jwtDecode } from 'jwt-decode';
import { Acteur } from '../../../models/Actor';
import LoadingButton from '../../LodingButton/LoadingButton';

const Post = ({ handleCloseModal,type }) => {
    const id = jwtDecode(authService.getToken()).id;
    const [acteur, setActeur] = useState(Acteur);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imgError, SetmgError] = useState('');
    const [loading, setLoading] = useState(false);

    const [boxOpen,setBoxOpen]=useState(false);
    const [isToggleOn, setIsToggleOn] = useState(false);
    const [isButtonVisible, setIsButtonVisible] = useState(false);

    const handleToggle=()=>{
        setIsToggleOn(!isToggleOn);
    }

    const notifySuccess = (message) => toast.success(message);

    useEffect(() => {
        handleGetUserById();
    }, []);

    const [formArticle, setFormArticle] = useState({
        title: '',
        description: '',
        type: '',
        acteur: {},
        images: []
      });

      const handleGetUserById = async () => {
        try {
            const actorData = await getItemById('actors', parseInt(id));
             setActeur({
                id: actorData.id,
                name: actorData.name,
                phoneOne: actorData.phoneOne,
                phoneTwo: actorData.phoneTwo,
                email: actorData.email,
                password: actorData.password,
                statut: actorData.statut,
                profession: actorData.profession,
                typeProduction: actorData.typeProduction,
                typeRace: actorData.typeRace,
                typeBatiment: actorData.typeBatiment,
                typeInfra: actorData.typeInfra,
                typeEspece: actorData.typeEspece,
                description: actorData.description,
                region: actorData.region,
                deptement: actorData.deptement,
                arrdissement: actorData.arrdissement,
                siteWeb: actorData.siteWeb,
                imgProfile: actorData.imgProfile,
                etatCompte: actorData.etatCompte,
                createAt: actorData.createAt,
                updateAt: actorData.updateAt,
            });

            setFormArticle((prevFormArticle) => ({
                ...prevFormArticle,
                acteur: {
                    id: actorData.id,
                    name: actorData.name,
                    phoneOne: actorData.phoneOne,
                    phoneTwo: actorData.phoneTwo,
                    email: actorData.email,
                    password: actorData.password,
                    statut: actorData.statut,
                    profession: actorData.profession,
                    typeProduction: actorData.typeProduction,
                    typeRace: actorData.typeRace,
                    typeBatiment: actorData.typeBatiment,
                    typeInfra: actorData.typeInfra,
                    typeEspece: actorData.typeEspece,
                    description: actorData.description,
                    region: actorData.region,
                    deptement: actorData.deptement,
                    arrdissement: actorData.arrdissement,
                    siteWeb: actorData.siteWeb,
                    imgProfile: actorData.imgProfile,
                    etatCompte: actorData.etatCompte,
                    createAt: actorData.createAt,
                    updateAt: actorData.updateAt,
                }
            }));
        } catch (error) {
            console.error(error.response.data.message);
        }
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormArticle((prevArticle) => ({
            ...prevArticle,
            [name]: value.trim()
        }));

        setIsButtonVisible(value.trim() !== '');
    }

    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files);
    
        if (selectedFiles.length + files.length <= 5) {
            try {
                const base64Promises = files.map(fileToBase64);
                const base64Images = await Promise.all(base64Promises);
                setFormArticle((prevFormArticle) => ({
                    ...prevFormArticle,
                    images: [...prevFormArticle.images, ...base64Images.map(base64Image => ({ base64Image }))]
                }));
                setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
            } catch (error) {
                console.error('Erreur lors de la conversion en base64 :', error);
            }
        } else {
            SetmgError("Vous ne pouvez télécharger que jusqu'à 5 images");
        }
    };
    
    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
        });
    };

    const handleRemove = (index) => {
        setSelectedFiles((prevFiles) => {
        const newFiles = [...prevFiles];
        newFiles.splice(index, 1);
        return newFiles;
        });
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();
    
        try {
            setLoading(true);
    
            const updatedFormArticle = {
                ...formArticle,
                type: type
            };
    
            console.log(updatedFormArticle);
    
            const response = await createItem('articles', updatedFormArticle);
        } catch (error) {
            console.error('Erreur lors de la soumission du formulaire :', error);
        } finally {
            setLoading(false);
            handleCloseModal();
            notifySuccess('Votre article a été publié.');
            window.location.href = window.location.href;
        }
    };

    return (
        <>
            <div className='postCenter'>
                <span>{((type==='vente') ? 'Effectuer Une Vente' : 'Publiez un article')}</span>
            </div>
            <hr />
            {imgError && <span className='imgerr'>{imgError}</span> }
            <form onSubmit={handleSubmitForm} className='formM'>
                <div className="formP">
                    <div className="title-post">
                        <label htmlFor="title">{type === 'vente' ? 'Que vendez-vous ?' : 'Titre de la publication'}</label>
                        <input type="text" name="title" onChange={handleInputChange}/>
                    </div>
                    <textarea 
                        onChange={handleInputChange}
                        name="description" 
                        className="text_input"
                        placeholder="Description"
                        autoFocus
                        spellCheck="true"
                        lang='en'
                    >

                    </textarea>
                </div>
                {
                    (!boxOpen) &&
                    <div className="img-post">
                        <span>Associer des images</span>
                        <FontAwesomeIcon icon={faImages} style={{fontSize:'1.5em',cursor:'pointer'}} onClick={()=>setBoxOpen(true)}/>
                    </div>
                }
                {
                    (boxOpen) && 
                    <>
                        <div className="formP">
                            <FontAwesomeIcon icon={faClose} onClick={()=>setBoxOpen(false)} style={{cursor:'pointer'}}/>
                            <label htmlFor="file">Associez des images</label>
                            <input type="file" id="file" name="images" accept='image/*' multiple onChange={handleFileChange}/>
                        </div>

                        <div className='previewBox'>
                            
                           {selectedFiles.map((file, index) => (
                                <div key={index} className='img_box' >
                                    {/* Aperçu de l'image */}
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt={`Preview ${index}`}
                                        style={{ Width: '20%', maxHeight: '8em'}}
                                    />
                                    {/* Bouton de suppression associé à chaque fichier */}
                                    <FontAwesomeIcon className='deleteImg' onClick={() => handleRemove(index)} icon={faTimesCircle}/>
                                </div>
                            ))}
                        </div>
                    </>
                }
                {!loading && 
                    <div className="postbtn">
                        <button type='button' className='cancel' onClick={handleCloseModal}>Annuler</button>
                        {isButtonVisible && <button type="submit" className='submit'>Publier</button>}
                    </div>
                }
                {loading && <button className="loading-btn"><LoadingButton color={"#fff"} loading={loading}/></button>}
            </form>
            <ToastContainer/>
        </>
    )
}
export default Post;
