import React from 'react'
import './signup.css';
import AccountForm from '../../../components/forms/AccountForm';
import { useNavigate } from 'react-router';
import NavBar from '../welcome/nav/NavBar';
import Footer from '../welcome/footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBackward, faLeftLong } from '@fortawesome/free-solid-svg-icons';

const Signup = () => {
    const navigate = useNavigate();
    const backPage =() => {
        navigate(-1)
    }

    const handleSubmit = async () => {
        // navigate("/verify");
    }
    
    return (
        <>
            {/* <NavBar /> */}
            <div  onClick={backPage} style={{cursor:'pointer',margin:'20px',position:'absolute'}}>
            <FontAwesomeIcon icon={faArrowLeft}/>
            <span>Retour</span>
            </div>
            <AccountForm mode="create" onSubmit={handleSubmit} />
            {/* <Footer/> */}
        </>
    );
}

export default Signup;
