import React from 'react';
import { Link } from 'react-router-dom';

import './header.css'

const Header = () => {
    return (
        // menu de navigation principale de la page welcome
        <div className="Header">
            <nav>
                <ul>
                    <li><Link to="/home">Accueil</Link></li>
                    <li><Link to="/signup">Inscription</Link></li>
                    <li><Link to="/login">Connexion</Link></li>
                    <li><Link to="/members/home">Espace membres</Link></li>
                </ul>
            </nav>
        </div>
    );
    
};

export default Header;