import React from 'react'
import './manual.css'
import { Link } from 'react-router-dom';

export default function Manual() {

    return (
        <div className="manual-container">
          <h1>Manuel d'utilisation de l'application web <Link to="https://ar1pdcvep.com">ar1pdcvep</Link></h1>
          <ul>
            <li>1. Présentation de l'application</li>
            <li>2. Créer un compte
              <ul>
                <li>Inscription</li>
                <li>Vérification de l'adresse e-mail</li>
                <li>Validation du compte par l'administrateur</li>
              </ul>
            </li>
            <li>3. Se connecter à l'application</li> <br/>
            <li>4. Réinitialiser mon mot de passe</li>
            <li>5. Espace personnel
              <ul>
                <li>Modifier mes informations</li>
                <li>Changer mon mot de passe</li>
              </ul>
            </li><br/>
            <li>6. Publier un article</li><br/>
            <li>7. Commenter un article</li><br/>
            <li>8. Interagir avec la communauté
              <ul>
                <li>Consulter les articles</li>
                <li>Réagir aux articles</li>
              </ul>
            </li><br/>
            <li>9. Assistance et support</li>
          </ul>
    
          <h2>1. Présentation de l'application</h2>
          <p>L'application web ar1pdcvep a été conçue pour mettre en relation les acteurs des filières d'élevage des catégories Porcine, Pisciculture et Bovine et dans régions CENTRE , SUD et EST du Cameroun. Elle permet aux utilisateurs de :</p>
          <ul>
            <li>Créer un compte et se connecter à la plateforme</li>
            <li>Publier des articles de vente ou annonces autour de leurs activités</li>
            <li>Commenter les articles publiés par les autres membres</li>
            <li>Interagir avec la communauté d'éleveurs</li>
          </ul>
    
          <h2>2. Créer un compte</h2>
          <h3>Comment s’enregistrer sur la plateforme</h3>
          <p>Pour créer un compte sur, entrez tout d’abord dans la section article en cliquant sur le menu « article » de la page d’accueil. Cliquez ensuite sur le bouton "S'inscrire" situé en haut à droite de la page d'accueil. Remplissez le formulaire d'inscription en saisissant vos informations personnelles et professionnelles. N'oubliez pas d'indiquer votre catégorie d'élevage et votre région.</p>
          <p>Une fois les informations bien renseignées, cliquez sur le bouton pour soumettre le formulaire.</p>
          <p><strong>NB :</strong></p>
          <ul>
            <li>Tous les champs obligatoires sont marqués par une étoile</li>
            <li>Vous devez renseigner une adresse mail valide et fonctionnelle</li>
          </ul>
    
          <h3>Vérification de l'adresse e-mail</h3>
          <p>Une fois votre inscription validée, vous recevrez un e-mail de confirmation contenant un code de vérification à 6 chiffres. Saisissez ce code dans le champ prévu à cet effet pour valider votre adresse e-mail.</p>
    
          <h3>Validation du compte</h3>
          <p>Après avoir vérifié votre adresse e-mail, votre demande de création de compte sera transmise à l'administrateur de la plateforme pour validation. Vous recevrez un e-mail de confirmation contenant votre mot de passe pour vous connecter sur la plateforme lorsque votre compte sera activé.</p>
          <p><strong>NB :</strong></p>
          <ul>
            <li>Ce mot de passe est temporaire, vous pouvez le modifier dans vos paramètres une fois connecté.</li>
            <li>La validation de votre compte peut prendre quelque temps, voire même 24h ou plus, mais n’hésitez pas à nous contacter en cas d’attente trop prolongée.</li>
          </ul>
    
          <h2>3. Se connecter à l'application</h2>
          <p>Une fois votre compte créé et validé, vous avez donc la possibilité de vous connecter à l'application en saisissant votre adresse e-mail et votre mot de passe dans les champs prévus à cet effet, puis en cliquant sur "Se connecter".</p>
    
          <h2>4. Réinitialiser mon mot de passe</h2>
          <p>Si vous avez oublié votre mot de passe, cliquez sur "Mot de passe oublié" sur la page de connexion. Entrez votre adresse e-mail, puis suivez les instructions envoyées par e-mail pour définir un nouveau mot de passe.</p>
    
          <h2>5. Espace personnel</h2>
          <p>Une fois connecté, cliquez sur l’icône de profil en haut à droite de votre écran et accédez à votre espace personnel.</p>
          <p>Depuis votre espace personnel, vous pouvez mettre à jour vos informations personnelles et professionnelles en cliquant sur l'icône d’engrenage tout en bas de vos informations. Vous aurez deux options : la première soit pour modifier votre mot de passe et la seconde pour modifier vos informations professionnelles.</p>
    
          <h3>Changer mon mot de passe</h3>
          <p>Pour changer votre mot de passe, rendez-vous dans les paramètres de votre compte, puis cliquez sur "Changer de mot de passe". Saisissez votre ancien mot de passe, puis entrez et confirmez votre nouveau mot de passe.</p>
    
          <h2>6. Publier un article</h2>
          <h3>Créer un nouvel article</h3>
          <p>Pour publier un nouvel article, cliquez sur le bouton "+" en bas à droite de la page d’accueil des articles une fois connecté. Vous aurez deux propositions d’icones (boutons) : la première pour publier une simple annonce et la seconde pour publier un article destiné à la vente. Remplissez le formulaire en indiquant le titre, le contenu, et les images si nécessaires, puis cliquez sur "Publier" pour publier votre article.</p>
    
          <h2>7. Commenter un article</h2>
          <p>Depuis un article, vous pouvez laisser un commentaire en saisissant votre message dans le champ prévu à cet effet, puis en cliquant sur "Publier".</p>
    
          <h2>8. Interagir avec la communauté</h2>
          <h3>Consulter les articles</h3>
          <p>Vous pouvez consulter les articles publiés par les autres membres en naviguant dans les différentes catégories et régions depuis le menu principal de l'application.</p>
    
          <h3>Réagir aux articles</h3>
          <p>Vous pouvez réagir en laissant un commentaire pour interagir avec l'auteur et les autres membres et aussi joindre l’auteur via WhatsApp en cliquant sur le lien en bas de l’article uniquement pour les articles destinés à la vente.</p>
    
          <h2>9. Assistance et support</h2>
          <p>Si vous rencontrez des difficultés ou avez des questions concernant l'utilisation de l'application, n'hésitez pas à contacter notre équipe d'assistance à l'adresse bonguea2@gmail.com.</p>
        </div>
      );
   
}
