import React, { useEffect, useState } from 'react'
import UserProfile from '@/components/userProfile/UserProfile'
import Feeds from '@/components/feed/Feeds'
import { getItemById, getPostsByUser } from '../../../services/Crud';
import { useParams } from 'react-router-dom';
import Separate from '../../../components/separate/Separate';

const Profile = () => {

  const { id } = useParams();
  const [ loading, setLoading] = useState(true);

  const [selectedUser, setSelectedUser] = useState({});
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    handleGetUserById();
    if (selectedUser) {
      handleGetPostsByUser();
    }
  },[id]);

  const handleGetUserById = async () =>{
    try {
      const user = await getItemById('actors', parseInt(id));
      setSelectedUser(user);
      setPosts(user.articles);
      setLoading(false);
    } catch (error) {
      console.error(error.response.data.message);
      setLoading(false);
    }
  }
  const handleGetPostsByUser = async () =>{
    try {
      const articles = await getPostsByUser('articles/user', parseInt(id));
      setPosts(articles);
    } catch (error) {
      console.error(error.response.data.message);
    }
  }
  const counter=selectedUser.articles?.length;
  return (
    <>
      <UserProfile profil={selectedUser} loading={loading}/>
      <Separate profil={selectedUser}/>
      {
        counter > 0 && 
      <Feeds articles={posts} typeart="1"/>
      }
    </>
  )
}

export default Profile;
