import React, { useEffect, useState } from 'react';
import { getList } from '../../services/Crud';
// import './filterBar.css'; 

const FilterBar = ({ onFilterChange }) => {

  const [categories, setCategories] = useState([]);
  const [activeFilter, setActiveFilter] = useState('Tous');

  useEffect(() => {
    handleGetAllCategories();
  }, []);

  const handleGetAllCategories = async () => {
    try {
        const data = await getList('categories');
        setCategories(data);
    } catch (error) {
        console.error('Erreur lors de la récupération des catégories :', error);
    }
};

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
    onFilterChange(filter);
  };

  return (
    <div className="filter-bar">
      <div
        className={`filter-item ${activeFilter === 'Tous' ? 'active' : ''}`}
        onClick={() => handleFilterClick('Tous')}
      >
        Tous
      </div>
        {categories.map(cat => (
          <div key={cat.id}>
            <div
              className={`filter-item ${activeFilter === cat.catName ? 'active' : ''}`}
              onClick={() => handleFilterClick(cat.catName)}
              >
                {cat.catName}
            </div>
          </div>
        ))}
      {/* <div
        className={`filter-item ${activeFilter === 'Pisciculture' ? 'active' : ''}`}
        onClick={() => handleFilterClick('Pisciculture')}
      >
        Pisciculture
      </div>
      <div
        className={`filter-item ${activeFilter === 'Porc' ? 'active' : ''}`}
        onClick={() => handleFilterClick('Porc')}
      >
        Porc
      </div>
      <div
        className={`filter-item ${activeFilter === 'Bovin' ? 'active' : ''}`}
        onClick={() => handleFilterClick('Bovin')}
      >
        Bovin
      </div> */}
    </div>
  );
};

export default FilterBar;


