import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './footer.css'
import Pdcvep from "@/assets/icons/theLogo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocation, faPhone } from '@fortawesome/free-solid-svg-icons'

export default function Footer() {

  const currentYear = useState(new Date().getFullYear());

  return (
    <div className='content' id='a-contact'>
      <footer class="footer-distributed">
        <div class="footer-left">
          <h3>AR1/PDCVEP<span><img src={Pdcvep}/></span></h3>
          <p class="footer-links">
            <Link to="/" className='link-1'>Accueil</Link>
            <Link to="members/home">Articles</Link>
            <a href="#a-propos">A propos</a>
            <Link to="privacypolicy" target='_blank'>Privacy Policy</Link>
            <Link to="guide" target='_blank'>Guide</Link>
          </p>

          <p class="footer-company-name">AR1 PDCVEP © {currentYear}</p>
        </div>
        <div class="footer-center">
        <span className='footer-adresse'>Adresses</span>
          <div>
            <i><FontAwesomeIcon icon={faLocation}/></i>
            <p><span>Délegation Réginale du MINEPIA/Centre</span> Mvog Betsi, 6402, Yaoundé</p>
          </div>

          <div>
            <i><FontAwesomeIcon icon={faPhone}/></i>
            <p>(+237) 697 033 657</p>
          </div>

          <div>
            <i class="fa fa-envelope"></i>
            <p><a href="mailto:antenneregionale1pdcvep@gmail.com">antenneregionale1pdcvep@gmail.con</a></p>
          </div>

        </div>

        <div class="footer-right">

          <p class="footer-company-about">
            <span>A propos</span>
            Cette plateforme offre des oportunités uniques et met en réseau les acteurs des differentes filières  de l'antenne n°1 du PDCVEP.
          </p>

          <div class="footer-icons">

            <a href="#"><i class="fa fa-facebook"></i></a>
            <a href="#"><i class="fa fa-twitter"></i></a>
            <a href="#"><i class="fa fa-linkedin"></i></a>
            <a href="#"><i class="fa fa-github"></i></a>

          </div>

        </div>
      </footer>
      <div className="row-copy">
      <hr/>
        <Link to="https://dirgitaltech.com" target='_blank'>Dirgital-Tech</Link> Copyright © {currentYear} <Link to="https://dirgitaltech.com" target='_blank'>Dirgital-Tech</Link> - All rights reserved || Designed By: <Link to="https://dirgitaltech.com" target='_blank'>Dirgital-Tech</Link>
        </div>
    </div>
  )
}
