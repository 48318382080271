import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { authService } from '../../services/auth/auth.service';
import './dropdown.css'
import { jwtDecode } from 'jwt-decode';

const DropDown = () => {
    const navigate = useNavigate();
    // const [id, setId] = useState();

    // useEffect(() => {
    //     let token = localStorage.getItem("token");
    //     setId(token ? jwtDecode(token).id : "")
    // }, [])

    const logout = () => {
        authService.logout();
        navigate("/home");
    }
    return (
        <div className="dropdown-menu">
            <ul className="ul-menu">
                <Link><li >Mon compte</li></Link>
                <li onClick={logout}>Deconnexion</li>
            </ul>
        </div>
    );
};

export default DropDown;