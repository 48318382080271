import React, { useState } from 'react'
import './login.css'
import { Link, useNavigate } from 'react-router-dom'
import { createItem, generateOtp } from '../../../services/Crud'
import { authService } from '../../../services/auth/auth.service'
import LoadingButton from '@/components/LodingButton/LoadingButton'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form'

const Password = () => {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [err, setErr] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    
    const backPage =() => {
        navigate(-1)
    }

    const Submitform = async (data) => {
        try {
            setLoading(true);
            await generateOtp('users', data.email)
                .then(res => {
                    navigate(`/verifypwd/${data.email}`);
                    console.log(res);
                })
        } catch (error) {
            setErr(true);
            setErrorMessage(error.response.data.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            {/* <NavBar/> */}
            <div  onClick={backPage} style={{cursor:'pointer',margin:'20px',position:'absolute'}}>
            <FontAwesomeIcon icon={faArrowLeft}/>
            <span>Retour</span>
            </div>
            <div className="l-body">
                <div className="login-container">
                    <div className="l-title">
                        <span>Renseignez l'adresse mail associé à votre compte</span>
                        <hr />
                        {err && (
                            <span className='error-message'>{errorMessage}</span>
                        )}
                    </div>
                        <form onSubmit={handleSubmit(Submitform)}>
                            <div className="form-group">
                                <input type="text" {...register('email', {required: true, email: true})} id="login" required />
                                <label htmlFor="login">Email</label>
                                <span className="icon">&#128100;</span>
                            </div>
                        {!loading && <button type="submit" className="submit-btn">Envoyer</button>}
                        {loading && <button className="loading-btn"><LoadingButton color={"#fff"} loading={loading}/></button>}
                        </form>
                        <div className="create-account">
                            <Link to="/login">Se connecter ?</Link>
                            <p>Vous n'avez pas de compte ? <Link to="/signup">Creer un compte</Link></p>
                        </div>
                    </div>
            </div>
           
            {/* <Footer/> */}
        </>
    )
}

export default Password
