export const Acteur = {
    id:         null,
    name:       '',
    phoneOne:   '',
    phoneTwo:   '',
    email:      '',
    password:   '',
    statut: false,
    profession: '',
    typeProduction: '',
    typeRace: '',
    typeBatiment: '',
    typeInfra: '',
    typeEspece: '',
    description: '',
    region: '',
    deptement: '',
    arrdissement: '',
    siteWeb: '',
    imgProfile: '',
    etatCompte: false,
    createAt:   null,
    updateAt:   null,
}
